<template>
  <carousel
    v-bind="props"
    ref="carousel"
    class="v2-carousel"
    :class="[
      theme,
      paginationTheme ? `pagination-${paginationTheme}` : '',
      navigationTheme ? `navigation-${navigationTheme}` : '',
      navigationOuter ? 'navigation-outer' : '',
    ]"
    @slide-start="slideStart"
  >
    <slot :slide="childComponent" />

    <template #addons>
      <div class="carousel-navigation">
        <navigation>
          <template #next>
            <i class="icon" :class="navigationOuter ? 'icon-arrow-right-thin' : 'icon-angle-right'"></i>
          </template>
          <template #prev>
            <i class="icon" :class="navigationOuter ? 'icon-arrow-left-thin' : 'icon-angle-left'"></i>
          </template>
        </navigation>
      </div>
      <app-carousel-pagination :current="currentSlide" :count="maxSlide" @pagination-click="slideTo" />
    </template>
  </carousel>
</template>

<script>
import { Carousel, Navigation, Slide } from "vue3-carousel";
import AppCarouselPagination from "@/components/v2/app-carousel-pagination/index.vue";

export default {
  name: "app-carousel",
  components: { AppCarouselPagination, Carousel, Navigation },
  props: {
    theme: {
      type: String,
      required: false,
      default: null,
      validator: (value) => {
        return ["light", "dark", null].includes(value);
      },
    },
    paginationTheme: {
      type: String,
      required: false,
      default: null,
      validator: (value) => {
        return ["light", "dark", null].includes(value);
      },
    },
    navigationTheme: {
      type: String,
      required: false,
      default: null,
      validator: (value) => {
        return ["light", "dark", null].includes(value);
      },
    },
    navigationOuter: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["pageChange"],
  data() {
    return {
      currentSlide: 0,
      maxSlide: 0,
    };
  },
  computed: {
    props() {
      return {
        ...{ itemsToShow: 1, snapAlign: "start" },
        ...this.$attrs,
      };
    },
    childComponent() {
      return Slide;
    },
  },
  methods: {
    slideStart(params) {
      this.maxSlide = params.slidesCount;
      this.currentSlide = params.slidingToIndex;
      this.$emit("pageChange", params);
    },
    slideTo(index) {
      this.$refs.carousel.slideTo(index);
    },
  },
  mounted() {
    // fix ios 17 bug with render
    window.dispatchEvent(new Event("resize"));

    setTimeout(() => {
      if (this.$refs.carousel) {
        this.$refs.carousel.updateSlidesData();
        this.maxSlide = this.$refs.carousel.data.maxSlide.value + 1;
      }
    }, 500);
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
