import { loadVideoQuality, loadVolumeValue, setVideoQuality, setVolumeValue } from "@/utils/storage";

export default () => {
  return {
    namespaced: true,
    state: {
      theaterMode: false,
      recId: null,
      isPipModeEnabled: false,
      vrType: null,
      quality: loadVideoQuality() || "auto",
      volume: loadVolumeValue() !== null ? loadVolumeValue() : 1.0,
    },
    getters: {
      isTheaterMode: (state) => state.theaterMode,
      getRecommendationId: (state) => state.recId,
      isPipModeEnabled: (state) => state.isPipModeEnabled,
      getVrType: (state) => state.vrType,
      getQuality: (state) => state.quality,
      getVolume: (state) => state.volume,
    },

    mutations: {
      SET_THEATER_MODE(state, value) {
        state.theaterMode = value;
      },
      SET_RECOMMENDATION_ID(state, recId) {
        state.recId = recId;
      },
      SET_PIP_MODE(state, value) {
        state.isPipModeEnabled = value;
      },
      SET_VR_TYPE(state, vrType) {
        state.vrType = vrType;
      },
      SET_QUALITY(state, quality) {
        state.quality = quality;
      },
      SET_VOLUME(state, volume) {
        state.volume = volume;
      },
    },

    actions: {
      setTheaterMode({ commit, dispatch, rootGetters }, { videoId, recId = null, vrType = null }) {
        const activeVideoId = rootGetters["video/getActiveVideo"];

        if (activeVideoId !== videoId) {
          commit("SET_THEATER_MODE", videoId !== null);
          commit("SET_RECOMMENDATION_ID", recId);
          commit("SET_VR_TYPE", vrType);
          dispatch("video/setActiveVideo", { videoId }, { root: true });
        } else {
          dispatch("setPipMode", false);
        }
      },
      setPipMode({ commit }, value) {
        commit("SET_PIP_MODE", value);
      },
      setQuality({ commit }, { quality }) {
        setVideoQuality(quality);
        commit("SET_QUALITY", quality);
      },
      setVolume({ commit }, { volume }) {
        setVolumeValue(volume);
        commit("SET_VOLUME", volume);
      },
    },
  };
};
