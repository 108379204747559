<template>
  <div class="carousel-update-item" @click="onClick">
    <div class="image-wrapper">
      <img v-lazy="thumbnail" :alt="update['title_' + lang]" />
    </div>
    <p class="title">{{ update["title_" + lang] }}</p>
  </div>
</template>

<script>
import { getUpdateMiniThumb } from "@/utils/mediaHelper";
import { getLink } from "@/utils/updateHelper";

export default {
  name: "carousel-update-item",
  props: {
    update: {
      type: Object,
      required: true,
    },
  },
  computed: {
    thumbnail() {
      return getUpdateMiniThumb(this.update.thumb);
    },
    lang() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    getLink,
    onClick() {
      this.$router.push(this.getLink(this.update));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./index";
</style>
