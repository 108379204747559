<template>
  <button type="button" class="app-button btn" :disabled="isDisabled" :class="classes" @click="onClick">
    <span v-if="icon" :class="icon" />
    {{ title }}
  </button>
</template>

<script>
export default {
  name: "app-button",

  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    isPrimary: {
      type: Boolean,
      default: false,
    },
    borderDark: {
      type: Boolean,
      default: true,
    },
    small: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      required: false,
      default: true,
    },
    icon: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["onClick"],

  computed: {
    classes() {
      return {
        "btn-primary": this.isPrimary,
        "btn-blue": !this.isPrimary,
        "border-dark": this.borderDark,
        "w-100": this.fullWidth,
        "btn-sm": this.small,
        "btn-icon": this.icon,
      };
    },
  },

  methods: {
    onClick() {
      this.$emit("onClick");
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
