import request from "@/api/request";

class FeedsService {
  static async getHeroVideo() {
    return await request.get(`/feeds/hero`);
  }

  static async getFeed(type, offset, rec_id = null) {
    return await request.get(`/feeds/${type}`, { offset, rec_id });
  }

  static async getSimilar(id) {
    return await request.get(`/feeds/similar/${id}`);
  }
}

export default FeedsService;
