import updateService from "@/api/services/updateService";

export default () => {
  return {
    namespaced: true,
    state: {
      new_and_upcoming: null,
      latest: null,
    },

    getters: {
      getNewAndUpcoming: (state) => state.new_and_upcoming,
      getLatest: (state) => state.latest,
    },

    mutations: {
      SET_NEW_AND_UPCOMING(state, { res }) {
        state.new_and_upcoming = res;
      },
      SET_LATEST(state, { res }) {
        state.latest = res;
      },
    },

    actions: {
      async getNewAndUpcoming({ commit }, payload) {
        const res = await updateService.getNewAndUpcoming(payload?.date);
        commit("SET_NEW_AND_UPCOMING", { res });
      },
      async getLatest({ commit }) {
        const res = await updateService.getLatest();
        commit("SET_LATEST", { res });
      },
    },
  };
};
