import ratingService from "../api/services/ratingService";

export default () => {
  return {
    namespaced: true,
    state: {
      ratings: [],
      busy: {},
    },

    getters: {
      getRating: (state) => (type, id) => {
        if (state.ratings[type]) {
          const i = state.ratings[type].map((item) => item.entity_id).indexOf(id);

          if (i > -1) {
            return state.ratings[type][i].rating;
          }
        }

        return null;
      },
      getRatings: (state) => (type) => state.ratings[type] || null,
    },

    mutations: {
      ADD_RATINGS(state, { type, ratings }) {
        const newRating = {};
        newRating[type] = ratings;

        state.ratings = {
          ...state.ratings,
          ...newRating,
        };
      },

      SET_RATING(state, { type, id, value }) {
        const newRatingEntity = {
          entity_id: id,
          rating: value,
        };

        const ratings = state.ratings[type] || [];

        if (ratings) {
          const i = state.ratings[type] ? state.ratings[type].map((item) => item.entity_id).indexOf(id) : -1;

          if (i > -1) {
            state.ratings[type].splice(i, 1);
          }
        }

        ratings.push(newRatingEntity);

        const newRating = {};
        newRating[type] = ratings;

        state.ratings = {
          ...state.ratings,
          ...newRating,
        };
      },
      SET_BUSY(state, { type, is_busy }) {
        const busy = {};
        busy[type] = is_busy;

        state.busy = {
          ...state.busy,
          ...busy,
        };
      },
    },

    actions: {
      async pullRatings({ commit, state }, { type }) {
        if (state.busy[type]) {
          return;
        }

        commit("SET_BUSY", { type, is_busy: true });

        const ratings = await ratingService.getRatings(type);

        commit("ADD_RATINGS", { type, ratings });
        commit("SET_BUSY", { type, is_busy: false });
      },

      async updateRating({ commit }, { type, id, value, rec_id }) {
        const newAvgRating = await ratingService.updateRating({
          type,
          id,
          rating: value,
          rec_id,
        });

        commit("SET_RATING", { type, id, value });

        return newAvgRating;
      },
    },
  };
};
