import defaultRoutes from "./routes";
import ioRoutes from "@/router/ioRoutes";
import { metaHook } from "@/router/metaHook";
import store from "../store";
import { createRouter, createWebHistory } from "vue-router";
import { ROUTE_SCROLL_DELAY } from "@/utils/variables";
import { loadTld } from "@/utils/storage";
import playerMiddleware from "@/router/middleware/playerMiddleware";

const tld = loadTld() || window.location.origin.split(".").pop();
const routes = tld === "io" ? ioRoutes : defaultRoutes;

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to) {
    if (to.hash === "#") {
      return null;
    }

    if (to.hash.includes("#play-")) {
      return null;
    }

    if (to.hash) {
      const hashEl = to.hash ? document.getElementById(to.hash.replace("#", "")) : null;

      if (hashEl) {
        return {
          el: hashEl,
          behavior: "smooth",
        };
      }

      return null;
    }

    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ top: 0 });
      }, ROUTE_SCROLL_DELAY);
    });
  },
});

router.beforeEach(async (to, from, next) => {
  await store.dispatch("modal/hideAllModals");

  if (from.path !== to.path) {
    store.dispatch("layout/setGlobalLoading", true);
  }

  store.dispatch("layout/setContentLoaded", false);
  store.dispatch("layout/setPagePosition", { path: from.fullPath, top: window.scrollY });

  next();
});

router.afterEach(() => {
  store.dispatch("layout/setGlobalLoading", false);
});

router.afterEach(metaHook);
router.afterEach(playerMiddleware);

export default router;
