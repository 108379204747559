import { deleteCookie, getCookie, setCookie } from "@/utils/cookies";
import { APPROVED_COOKIES_COOKIE, ENV_STAGING } from "@/utils/variables";
import Bugsnag from "@bugsnag/js";

const ACCESS_TOKEN_STORAGE_KEY = "access_token";
const SESSION_STORAGE_KEY = "PHPSESSID";
const LANGUAGE_TOKEN_STORAGE_KEY = "language";
const VIDEO_QUALITY_STORAGE_KEY = "video_quality";
const TLD_STORAGE_KEY = "tld";
const VOLUME_VALUE_STORAGE_KEY = "volume_value";
export const VISIT_UID_KEY = "vID";

const load = (key) => {
  const storage = getStorage();

  const serializedValue = storage?.getItem(key) || getCookie(key);

  try {
    return JSON.parse(serializedValue);
  } catch (e) {
    console.debug(key, serializedValue);
    Bugsnag.notify(e);
    remove(key);
    return null;
  }
};

const set = (key, value) => {
  if (typeof value !== "undefined") {
    const storage = getStorage();

    if (storage) {
      try {
        storage.setItem(key, JSON.stringify(value));
      } catch (e) {
        Bugsnag.notify(e);
        console.debug("set cookie, exception", key);
        setCookie(key, JSON.stringify(value), 365);
      }
    } else {
      console.debug("set cookie, no local storage", key);
      setCookie(key, JSON.stringify(value), 365);
    }
  } else {
    remove(key);
  }
};

const remove = (key) => {
  const storage = getStorage();

  if (storage) {
    storage.removeItem(key);
  }

  deleteCookie(key);
};

const getStorage = () => {
  try {
    return localStorage;
  } catch (e) {
    console.log(e.message);
  }

  return null;
};

export const loadAccessToken = () => {
  // todo: temp added because its needed to run experiment to get use on backend
  return process.env.VUE_APP_ENV === ENV_STAGING ? load(ACCESS_TOKEN_STORAGE_KEY) : getCookie(ACCESS_TOKEN_STORAGE_KEY);
};

export const getVisitUid = () => {
  return getCookie(VISIT_UID_KEY) || load(VISIT_UID_KEY);
};

export const setVisitUid = (uid) => {
  set(VISIT_UID_KEY, uid);
};

export const setAccessToken = (value) => {
  set(ACCESS_TOKEN_STORAGE_KEY, value);
  // todo: temp added because its needed to run experiment to get use on backend
  setCookie(ACCESS_TOKEN_STORAGE_KEY, value, 365);
};

export const setApprovedCookies = () => {
  set(APPROVED_COOKIES_COOKIE, true);
};

export const getApprovedCookies = () => {
  return load(APPROVED_COOKIES_COOKIE);
};

export const removeAccessToken = () => {
  remove(ACCESS_TOKEN_STORAGE_KEY);
  // todo: temp added because its needed to run experiment to get use on backend
  deleteCookie(ACCESS_TOKEN_STORAGE_KEY);
  deleteCookie(SESSION_STORAGE_KEY);
};

export const setLanguage = (language) => {
  set(LANGUAGE_TOKEN_STORAGE_KEY, language);
};

export const loadLanguage = () => {
  return load(LANGUAGE_TOKEN_STORAGE_KEY);
};

export const setVideoQuality = (quality) => {
  set(VIDEO_QUALITY_STORAGE_KEY, quality);
};

export const loadVideoQuality = () => {
  return load(VIDEO_QUALITY_STORAGE_KEY);
};

export const setVolumeValue = (value) => {
  set(VOLUME_VALUE_STORAGE_KEY, value);
};

export const loadVolumeValue = () => {
  return load(VOLUME_VALUE_STORAGE_KEY);
};

export const loadTld = () => {
  return load(TLD_STORAGE_KEY);
};

export const setPopup = (id, show, data) => {
  set(id, { show: show, data: data });
};

export const updatePopup = (id, data) => {
  const popupShow = getPopup(id)?.show ?? false;

  const popupData = getPopup(id)?.data ?? {};
  const newData = { ...popupData, ...data };

  set(id, { show: popupShow, data: newData });
};

export const getPopup = (id) => {
  return load(id);
};

export const removePopup = (id) => {
  return remove(id);
};
