<template>
  <div class="menu-sort" :class="theme">
    <label>{{ $t("Sort by") }}</label>
    <div class="dropdown" @click="toggleList">
      <div class="selected">
        {{ $t(selected ? selected.label : defaultOption.label) }}
      </div>
      <div class="list" :class="{ expanded: expanded }">
        <div v-for="option in options" :key="option.id" class="list-item" @click="setOption(option)">
          {{ option.label }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { amplitudeTrack } from "@/utils/amplitude";

export default {
  name: "menu-sort-input",
  props: {
    defaultOption: {
      type: Object,
      required: true,
      default: null,
    },
    options: {
      type: Array,
      required: true,
      default: () => [],
    },
    theme: {
      type: String,
      required: false,
      default: "default",
      validator: (value) => {
        return ["default", "dark"].includes(value);
      },
    },
  },
  emits: ["onSelect"],
  data() {
    return {
      expanded: false,
      selected: null,
    };
  },
  methods: {
    toggleList() {
      this.expanded = !this.expanded;
    },
    closeList(e) {
      if (!this.$el.contains(e.target)) {
        this.expanded = false;
      }
    },

    setOption(option) {
      this.selected = option;
      this.$emit("onSelect", option);

      amplitudeTrack("Sorting order changed", {
        "New value": option.label,
        Page: location.pathname,
      });
    },
  },
  mounted() {
    document.addEventListener("click", this.closeList);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.closeList);
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
