import store from "../../store/index";
import { amplitudeVideoTrack } from "@/utils/amplitude";

export default (player) => {
  const controls = player?.controlBar;

  const toggleBtnClasses = () => {
    const isPipEnabled = store.getters["player/isPipModeEnabled"];
    pipButton.toggleClass("vjs-icon-picture-in-picture-enter", !isPipEnabled);
    pipButton.toggleClass("vjs-icon-picture-in-picture-exit", isPipEnabled);
  };

  const pipButton = controls.addChild(
    "button",
    {
      clickHandler: () => {
        const isPipEnabled = store.getters["player/isPipModeEnabled"];

        if (document.fullscreenElement) {
          document.exitFullscreen();

          if (isPipEnabled) {
            return;
          }
        }

        store.dispatch("player/setPipMode", !isPipEnabled);
        amplitudeVideoTrack("Video Pip Mode Toggle");

        toggleBtnClasses();
      },
    },
    18,
  );

  pipButton.addClass("pip-icon");
  toggleBtnClasses();
};
