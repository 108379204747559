import request from "@/api/request";

class BlogService {
  static async getPost({ lang, alias }) {
    return await request.get(`/blog/${lang}/post/${alias}`);
  }

  static async getList({ lang, page, title, category }) {
    return await request.get(`/blog/${lang}`, { page, title, category });
  }

  static async getCategories({ lang }) {
    return await request.get(`/blog/${lang}/categories`);
  }
}

export default BlogService;
