import { getTld } from "@/utils/urlHelper";
import { COMPANY_COMBINATOR, COMPANY_EEI, COMPANY_EFC } from "@/utils/variables";
import featureService from "@/api/services/featureService";

export default () => {
  return {
    namespaced: true,
    state: {
      busy: false,
      company: null,
    },
    getters: {
      getCompany: (state) => state.company,
      isBusy: (state) => state.busy,
      isEEI: (state) => state.company === COMPANY_EEI,
      isCombinator: (state) => state.company === COMPANY_COMBINATOR,
      isEFC: (state) => state.company === COMPANY_EFC,
    },
    mutations: {
      SET_COMPANY(state, company) {
        state.company = company;
      },
      SET_BUSY(state, busy) {
        state.busy = busy;
      },
    },
    actions: {
      async getCompany({ commit, state, rootGetters }, apiCheck = true) {
        if (state.busy) {
          return;
        }

        let company;

        const tld = getTld();

        if (tld === "net") {
          company = COMPANY_EEI;
        } else if (tld === "de") {
          company = COMPANY_EFC;
        } else if (tld === "ch") {
          company = COMPANY_COMBINATOR;
        } else {
          if (apiCheck && state.busy) {
            return;
          }

          const user = rootGetters["user/getProfile"];
          const visit = rootGetters["visit/getVisit"];

          if (user) {
            company = user.company;
          } else if (visit?.features?.eei || visit?.features?.combinator) {
            company =
              visit?.features?.combinator?.is_active || !visit?.features?.eei
                ? visit?.features?.combinator?.version
                : visit?.features?.eei?.version;
          } else {
            try {
              if (apiCheck) {
                commit("SET_BUSY", true);
              }

              company = apiCheck ? await featureService.getCompany() : COMPANY_EFC;
            } catch {
              company = COMPANY_EFC;
            }
          }
        }

        if (apiCheck) {
          commit("SET_BUSY", false);
        }

        commit("SET_COMPANY", company);
      },
    },
  };
};
