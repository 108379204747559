import likeService from "../api/services/likeService";

export default () => {
  return {
    namespaced: true,
    state: {
      likes: [],
      busy: {},
    },

    getters: {
      isLiked: (state) => (type, id) => state.likes[type] && state.likes[type].map((item) => item.entity_id).indexOf(id) > -1,
      getLikes: (state) => (type) => state.likes[type] || null,
    },

    mutations: {
      ADD_LIKES(state, { type, likes }) {
        const newLikes = {};
        newLikes[type] = likes;

        state.likes = {
          ...state.likes,
          ...newLikes,
        };
      },

      SET_LIKE(state, { type, id }) {
        const likes = state.likes[type] || [];

        if (likes) {
          const i = state.likes[type].map((item) => item.entity_id).indexOf(id);

          if (i > -1) {
            state.likes[type].splice(i, 1);
          } else {
            const newLikeEntity = {
              entity_id: id,
            };

            likes.push(newLikeEntity);
          }
        }

        const newLikes = {};
        newLikes[type] = likes;

        state.likes = {
          ...state.likes,
          ...newLikes,
        };
      },
      SET_BUSY(state, { type, is_busy }) {
        const busy = {};
        busy[type] = is_busy;

        state.busy = {
          ...state.busy,
          ...busy,
        };
      },
    },

    actions: {
      async pullLikes({ commit, state }, { type }) {
        if (state.busy[type]) {
          return;
        }

        commit("SET_BUSY", { type, is_busy: true });
        const likes = await likeService.getLikes(type);

        commit("ADD_LIKES", { type, likes });
        commit("SET_BUSY", { type, is_busy: false });
      },
      async updateLike({ commit, dispatch }, { type, id }) {
        await likeService.updateLike({ type, id });

        commit("SET_LIKE", { type, id });

        if (type === "comment") {
          dispatch("comments/updateLikeCount", { id }, { root: true });
        }
      },
    },
  };
};
