<template>
  <template v-if="simple">
    <button v-if="isSingleModel" class="btn btn-primary border-dark model-tip-button ps-2 pe-3" @click="onClick(models[0])">
      {{ singleModelLabel }}
    </button>

    <div
      v-else
      v-click-outside="closeDropdown"
      class="btn btn-primary border-dark model-tip-button"
      :class="{ expanded: expanded }"
      @click="toggleList"
    >
      {{ $t("Send a tip!") }}

      <ul class="dropdown-options">
        <li v-for="model in models" :key="model.id" @click="onClick(model)">{{ getModelName(model) }}</li>
      </ul>
    </div>
  </template>

  <div v-else class="model-tip box">
    <clouds />
    <h4 v-if="isSingleModel">{{ $t("Love [model]’s videos?").replace("[model]", models[0]["name_" + lang]) }}</h4>

    <button v-if="isSingleModel" type="button" class="btn btn-primary border-dark model-tip-button w-100" @click="onClick(models[0])">
      {{ singleModelLabel }}
    </button>

    <div
      v-else
      v-click-outside="closeDropdown"
      class="btn btn-primary border-dark model-tip-button"
      :class="{ expanded: expanded }"
      @click="toggleList"
    >
      {{ $t("Send a tip!") }}

      <ul class="dropdown-options">
        <li v-for="model in models" :key="model.id" @click="onClick(model)">{{ getModelName(model) }}</li>
      </ul>
    </div>
  </div>
</template>

<script>
import Clouds from "@/components/clouds";
import { POPUP_MODEL_TIP } from "@/utils/variables";
import { amplitudeTrack } from "@/utils/amplitude";
import { getModelName } from "@/utils/modelHelper";

export default {
  name: "model-tip-button",
  components: { Clouds },
  props: {
    models: {
      type: Array,
      required: true,
    },
    location: {
      type: String,
      required: false,
      default: "profile page",
    },
    simple: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["onClick"],
  data() {
    return {
      expanded: false,
    };
  },
  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
    isSingleModel() {
      return this.models.length === 1;
    },
    singleModelLabel() {
      return this.$t("Send [model] a tip!").replace("[model]", getModelName(this.models[0]));
    },
  },
  methods: {
    getModelName(model) {
      return getModelName(model);
    },
    onClick(model) {
      this.$emit("onClick");

      amplitudeTrack("Tip Clicked", {
        Page: this.$route.path,
        Model: model.name_en,
        "Model ID": model.id,
        Location: this.location,
      });

      this.$store.dispatch("popup/show", { id: POPUP_MODEL_TIP, data: { model: model.id } });
    },

    toggleList() {
      this.expanded = !this.expanded;
    },
    closeDropdown() {
      this.expanded = false;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
