import request from "@/api/request";

class VideoService {
  static async getList({ page, tags, order, type, id, term, search_type }) {
    return request.get("/videos", { page, tags, order, type, id, term, search_type });
  }

  static async getVideo(id) {
    return request.get("/videos/" + id);
  }

  static async search(term, signal) {
    return request.get("/videos/search", { term }, signal);
  }

  static async playback({ videoId, duration, startDate, timelineStart, rec_id = null }) {
    return request.post("/videos/playback", { videoId, duration, startDate, timelineStart, rec_id });
  }

  static async incompleteSearch({ term, suggested_tag_count }) {
    return request.post("/videos/incomplete_search", { term, suggested_tag_count });
  }

  static async getUnlockedVideos() {
    return request.get("/videos/unlocked");
  }

  static async setUnlockedVideos(token) {
    return request.post("/videos/unlocked", { token });
  }

  static async getPlaybackData(id) {
    return request.get("/videos/playbacks/" + id);
  }

  static async getRandom() {
    return request.get("/videos/random");
  }
}

export default VideoService;
