import request from "@/api/request";

class CommentsService {
  static async getComments(id, offset, limit, order) {
    return request.get(`/comments/${id}`, { offset, limit, order });
  }

  static async postComment(id, text, parent_id) {
    return request.post(`/comments/${id}`, { text, parent_id });
  }

  static async deleteComment(id) {
    return request.delete(`/comments/${id}`);
  }
}

export default CommentsService;
