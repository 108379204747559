import visitService from "../api/services/visitService";
import { EU_COUNTRIES } from "@/utils/variables";

export default () => {
  return {
    namespaced: true,
    state: {
      visit: null,
    },

    getters: {
      getVisit: (state) => state.visit,
      isEUCountry: (state) => EU_COUNTRIES.includes(state.visit?.country.toUpperCase()),
    },

    mutations: {
      SET_VISIT(state, visit) {
        state.visit = visit;
      },
    },

    actions: {
      async trackVisit({ commit }) {
        const visit = await visitService.trackVisit();

        commit("SET_VISIT", visit);
      },
    },
  };
};
