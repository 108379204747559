import store from "../store/index";

const { VUE_APP_HOTJAR_ID } = process.env;
let hotjarInited = false;

export const initHotjar = () => {
  if (hotjarInited) {
    return;
  }

  if (VUE_APP_HOTJAR_ID) {
    (function (h, o, t, j, a, r) {
      h.hj =
        h.hj ||
        function () {
          (h.hj.q = h.hj.q || []).push(arguments);
        };

      h._hjSettings = { hjid: VUE_APP_HOTJAR_ID, hjsv: 6 };
      a = o.getElementsByTagName("head")[0];
      r = o.createElement("script");
      r.async = 1;
      r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
      a.appendChild(r);
    })(window, document, "https://static.hotjar.com/c/hotjar-", ".js?sv=");

    const profile = store.getters["user/getProfile"];
    const visit = store.getters["visit/getVisit"] || {};

    const identifyId = profile?.id || visit.id;
    const props = {
      campaign: visit.campaign || "",
      country: visit.country,
      device: parseInt(visit.is_mobile) ? "mobile" : "desktop",
      browser: visit.browser,
    };
    const visitFeatures = visit.features;

    if (visitFeatures) {
      Object.keys(visitFeatures).forEach((key) => {
        const feature = visitFeatures[key];

        if (feature.version === null) {
          return;
        }

        props[key] = feature.version;
      });
    }

    window.hj("identify", identifyId, props);

    hotjarInited = true;
  }
};

export const sendHjEvent = (name) => {
  if (!window.hj) {
    return;
  }

  window.hj("event", name);
};
