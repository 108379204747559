import request from "@/api/request";
import i18n from "../../i18n";

class FavoriteService {
  static async getFavorites(type) {
    const lang = i18n.global.locale;
    return await request.get(`/favorites/${lang}/${type}`);
  }

  static async updateFavorite({ type, id, rec_id = null }) {
    const lang = i18n.global.locale;
    return await request.put(`/favorites/${lang}/${type}`, { id, rec_id });
  }

  static async getFavoriteList(type, offset = 0, perPage = 10) {
    const lang = i18n.global.locale;
    return await request.get(`/favorites/${lang}/${type}/items/short`, {
      page: offset,
      per_page: perPage,
    });
  }
}

export default FavoriteService;
