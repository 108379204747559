import request from "@/api/request";

class AuthService {
  static async login(username, password) {
    return await request.post("/auth/login", { username, password });
  }

  static async forgot_password(username, email) {
    return await request.post("/auth/forgot_password", { username, email });
  }

  static async google(token, language, landing_id = null) {
    return await request.post("/auth/google", { token, language, landing_id });
  }

  static async reset_password(password, reset_key) {
    return await request.post("/auth/reset_password", { password, reset_key });
  }

  static async login_by_hash(hash) {
    return await request.post("/auth/login_by_hash", { hash });
  }
}

export default AuthService;
