import request from "@/api/request";

class MessageService {
  static async getDialogs({ page }) {
    return request.get("/messages", { page });
  }

  static async getNewMessages(timestamp) {
    return request.get("/messages/new_messages", { last_message_timestamp: timestamp });
  }

  static async getDialogMessages({ type, name = null }) {
    let path = `/messages/${type}`;

    if (name !== null) {
      path += `/${name}`;
    }

    return request.get(path);
  }

  static async deleteDialogWith({ type, name }) {
    await request.delete(`/messages/${type}/${name}`);
  }

  static async sendMessage({ type, name, formData }) {
    return await request.post(`/messages/${type}/${name}`, formData, true);
  }

  static async getPlaydateConversationCameras(conversationId) {
    return await request.get("/messages/playdate_cameras", { id: conversationId });
  }

  static async getTransactionalMessagePopup() {
    return request.get("/messages/transactional/popup");
  }

  static async unsubscribeTransactionalMessageGroup(group) {
    return request.put(`/messages/unsubscribe/${group}`);
  }
}

export default MessageService;
