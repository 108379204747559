import i18n from "../i18n";
import { getTld } from "@/utils/urlHelper";

export const getTitlePrefix = () => {
  if (process.env.VUE_APP_TITLE_PREFIX) {
    return process.env.VUE_APP_TITLE_PREFIX;
  }

  return "Ersties." + getTld();
};

export const getTitle = (text, withPrefix = true) => {
  if (!withPrefix) {
    return i18n.global.t(text);
  }

  if (!text) {
    return getTitlePrefix();
  }

  return `${getTitlePrefix()} - ${i18n.global.t(text)}`;
};
