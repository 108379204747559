<template>
  <div class="comments">
    <comment-form :video-id="videoId" />

    <div class="comment-list">
      <div v-if="comments.length" class="comments-header">
        <div class="comment-count">{{ total }} {{ commentsWording }}</div>
        <menu-sort-input :options="sortOptions" :default-option="selectedSortOption" theme="dark" @on-select="onSortSelect" />
      </div>

      <template v-for="(comment, k) in comments" :key="k">
        <comment :comment="comment" :video-id="videoId" />
      </template>
    </div>

    <div class="d-flex justify-content-center">
      <app-button
        v-if="comments.length < paginationTotal"
        :title="$t('Load more')"
        :full-width="false"
        :small="true"
        @on-click="load(lastOrder)"
      />
    </div>
  </div>
</template>

<script>
import CommentForm from "@/components/comment-form/index.vue";
import Comment from "@/components/comment/index.vue";
import { mapGetters } from "vuex";
import AppButton from "@/components/app-button/index.vue";
import MenuSortInput from "@/components/menu-sort-input/index.vue";

export default {
  name: "comments",
  components: { MenuSortInput, AppButton, Comment, CommentForm },
  props: {
    videoId: {
      type: [Number, String],
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      perPage: 20,
      optionPopular: { id: "popular", label: this.$t("Popular") },
      optionRecent: { id: "recent", label: this.$t("Recent") },
      lastOrder: null,
    };
  },

  computed: {
    ...mapGetters("comments", { comments: "getComments", paginationTotal: "getTotal" }),
    commentsWording() {
      return this.total === 1 ? this.$t("Comment:") : this.$t("Comments:");
    },
    sortOptions() {
      return [this.optionPopular, this.optionRecent];
    },
    selectedSortOption() {
      let option = null;

      const i = this.sortOptions.map((item) => item.id).indexOf(this.lastOrder);

      if (i > -1) {
        option = this.sortOptions[i];
      }

      return option ?? this.optionRecent;
    },
  },

  methods: {
    async load(order = null) {
      const offset = order !== this.lastOrder ? 0 : this.comments.length;
      await this.$store.dispatch("comments/loadComments", { id: this.videoId, offset, limit: this.perPage, order });
    },
    async onSortSelect(order) {
      if (order.id === this.lastOrder) {
        return;
      }

      await this.$store.dispatch("comments/clearComments");
      await this.load(order.id);
      this.lastOrder = order.id;
    },
  },

  async beforeMount() {
    await this.$store.dispatch("comments/clearComments");
    await this.load();
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
