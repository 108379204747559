import { amplitudeTrack } from "@/utils/amplitude";
import authService from "@/api/services/authService";
import { setAccessToken } from "@/utils/storage";
import store from "../store/index";
import router from "@/router/router";
import i18n from "@/i18n";

class googleSignup {
  constructor() {
    this.initialized = false;
    this.fakeButton = null;
    this.events = [];
  }

  init() {
    if (store.getters["user/isLoggedIn"]) {
      return;
    }

    const host = window.location.hostname.split(".").slice(-2).shift();

    if (!["ersties", "localhost"].includes(host)) {
      return;
    }

    if (this.initialized) {
      this.#emit("ready");
      return;
    }

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";

    if (document.querySelector('[src="' + script.src + '"]')) {
      return;
    }

    window.onGoogleLibraryLoad = async () => {
      window.google.accounts.id.initialize({
        client_id: "506542501354-1uirdcnsnl36ajas93t12qg6vfrl1jau.apps.googleusercontent.com",
        callback: async (response) => {
          amplitudeTrack("Google response", { "select by": response.select_by });
          const route = router.currentRoute.value;

          try {
            const res = await authService.google(response.credential, i18n.global.locale, route.meta.landing_id);

            if (res.access_token) {
              setAccessToken(res.access_token);

              const redirectUrl = "/" + res.redirect_url.replace(/^\/|\/$/g, "");
              await router.push(redirectUrl);
            } else {
              setAccessToken(res);
              await router.push(route.query.redirect || "/recent_updates");
            }
          } catch (e) {
            this.#emit("error", e);
          }
        },
        context: "signin",
        ux_mode: "popup",
        auto_select: false,
        itp_support: true,
        use_fedcm_for_prompt: true,
      });

      this.initialized = true;
      this.togglePrompt();
      this.#createFakeButton();
      this.#emit("ready");
    };

    document.head.appendChild(script);
  }
  #emit(event, data) {
    const events = this.events[event] || [];

    events.forEach((event) => {
      event.call(null, data);
    });
  }
  #createFakeButton() {
    if (this.fakeButton) {
      return;
    }

    const googleLoginWrapper = document.createElement("div");

    googleLoginWrapper.style.display = "none";
    googleLoginWrapper.classList.add("custom-google-button");

    document.body.appendChild(googleLoginWrapper);

    window.google.accounts.id.renderButton(googleLoginWrapper, {
      type: "icon",
      width: 200,
      click_listener: () => {
        amplitudeTrack("Google signup button click");
      },
    });

    const googleLoginWrapperButton = googleLoginWrapper.querySelector("div[role=button]");

    this.fakeButton = {
      click: () => {
        googleLoginWrapperButton.click();
      },
    };
  }
  openPopup() {
    if (!this.fakeButton) {
      this.#createFakeButton();
    }

    this.fakeButton.click();
  }
  on(eventName, callback) {
    if (!this.events[eventName]) {
      this.events[eventName] = [];
    }

    this.events[eventName].push(callback);
  }
  off(eventName, callback) {
    if (!eventName) {
      this.events = [];
      return;
    }

    if (!this.events[eventName]) {
      return;
    }

    if (callback) {
      this.events[eventName] = this.events[eventName].filter((fn) => callback !== fn);
    } else {
      this.events[eventName] = null;
    }
  }
  togglePrompt() {
    if (!this.initialized) {
      return;
    }

    if (!store.getters["user/isLoggedIn"]) {
      window.google.accounts.id.prompt(() => {
        amplitudeTrack("Google prompt popup");
      });
    } else {
      window.google.accounts.id.cancel();
    }
  }
}
export default new googleSignup();
