import galleryService from "@/api/services/galleryService";

export default () => {
  return {
    namespaced: true,
    state: {
      galleries: [],
      galleryChapters: [],

      list: {},
      listBusy: false,
    },

    getters: {
      getList: (state) => state.list,
      isListBusy: (state) => state.listBusy,
      getGallery: (state) => (id) => state.galleries[id],
      getGalleryChapters: (state) => (id) => state.galleryChapters[id],
      getGalleryChapter: (state) => (galleryId, chapterId) => {
        return state.galleryChapters[galleryId]?.find((chapter) => chapter.id === chapterId) || null;
      },
    },

    mutations: {
      SET_GALLERY(state, gallery) {
        const newGallery = {};
        newGallery[gallery.id] = gallery;

        state.galleries = {
          ...state.galleries,
          ...newGallery,
        };
      },
      SET_GALLERY_CHAPTERS(state, { galleryId, chapters }) {
        const newGalleryChapters = {};
        newGalleryChapters[galleryId] = chapters;

        state.galleryChapters = {
          ...state.galleryChapters,
          ...newGalleryChapters,
        };
      },
      SET_LIST(state, list) {
        state.list = list;
      },
      SET_LIST_BUSY(state, value) {
        state.listBusy = value;
      },
    },

    actions: {
      async getGallery({ commit }, { id }) {
        const response = await galleryService.getGallery({ id });
        commit("SET_GALLERY", response);
        return response;
      },
      async getList({ commit, state }, params) {
        if (state.listBusy) {
          return;
        }

        commit("SET_LIST_BUSY", true);
        const response = await galleryService.getList({ page: params.page || 1, order: params.order || null });
        response.params = params;
        commit("SET_LIST", response);
        commit("SET_LIST_BUSY", false);
      },
      async getGalleryChapters({ commit }, { id }) {
        const response = await galleryService.getGalleryChapters({ id });

        commit("SET_GALLERY_CHAPTERS", {
          galleryId: id,
          chapters: response,
        });
      },
      async getGalleryChapter({ commit }, { galleryId, galleryChapterId }) {
        const response = await galleryService.getGalleryChapter({ galleryId, galleryChapterId });

        commit("SET_GALLERY_CHAPTERS", {
          galleryId: galleryId,
          chapters: [response],
        });
      },
    },
  };
};
