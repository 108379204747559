export default () => {
  return {
    namespaced: true,
    state: {
      shownModals: [],
    },

    getters: {
      getShownModals: (state) => state.shownModals,
      getShownModalById: (state) => {
        return (id) => {
          return state.shownModals.find((modal) => modal.id === id);
        };
      },
    },

    mutations: {
      SHOW_MODAL(state, modal) {
        const index = state.shownModals.findIndex((item) => item.id === modal.id);

        if (index === -1) {
          state.shownModals.push(modal);
        }
      },

      HIDE_MODAL(state, id) {
        state.shownModals = state.shownModals.filter((modal) => modal.id !== id);
      },

      HIDE_ALL_MODALS(state) {
        state.shownModals = [];
      },
    },

    actions: {
      showModal({ commit }, modal) {
        commit("SHOW_MODAL", modal);
      },

      hideModal({ commit, getters }, id) {
        const modal = getters.getShownModalById(id);

        if (modal) {
          commit("HIDE_MODAL", id);
        }
      },

      hideAllModals({ commit }) {
        commit("HIDE_ALL_MODALS");
      },
    },
  };
};
