<template>
  <div class="delete">
    <span class="delete-button" @click="onDelete">
      <i class="icon icon-trash-bin" />
    </span>

    <div v-if="showApprove" class="delete-approval">
      <p>{{ $t("Your comment will be permanently deleted. Are you sure?") }}</p>
      <div class="buttons">
        <span class="btn btn-sm" @click="onDeleteApprove">{{ $t("Yes, accept") }}</span>
        <span class="btn btn-sm" @click="onDeleteCancel">{{ $t("No, cancel") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "comment-delete",

  props: {
    commentId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      showApprove: false,
    };
  },

  methods: {
    onDelete() {
      this.showApprove = true;
    },
    onDeleteApprove() {
      this.$store.dispatch("comments/deleteComment", { id: this.commentId });
    },
    onDeleteCancel() {
      this.showApprove = false;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
