<template>
  <div class="popup-wrapper">
    <div v-click-outside="close" class="popup" :class="[customClass, { box: withClouds }]">
      <clouds v-if="withClouds" />
      <div v-if="canClose" class="close" @click="close"></div>
      <slot />
    </div>
  </div>
</template>

<script>
import Clouds from "@/components/clouds/index.vue";
import popupConfig from "@/configs/popup-config";

export default {
  name: "popup",
  components: { Clouds },
  props: {
    id: {
      type: String,
      required: false,
      default: "",
    },
    customClass: {
      type: String,
      required: false,
      default: "",
    },
    withClouds: {
      type: Boolean,
      required: false,
      default: false,
    },
    canClose: {
      type: Boolean,
      required: false,
      default: true,
    },
    showCaptcha: {
      type: Boolean,
      required: false,
      default: false,
    },
    forceClose: {
      type: Boolean,
      required: false,
      default: false,
    },
    closeTimeout: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  emits: ["onClose"],
  watch: {
    canClose(value) {
      if (value) {
        this.addCloseListener();
      }
    },
  },
  methods: {
    close(e) {
      if (!this.canClose || (this.showCaptcha && e && e.target.className !== "close")) {
        return;
      }

      this.$emit("onClose");
      this.$store.dispatch("popup/hide", { id: this.id, remove: this.forceClose });
    },
    addCloseListener() {
      window.addEventListener("keyup", (event) => {
        if (event.key === "Escape") {
          this.close();
        }
      });

      if (this.closeTimeout > 0) {
        setTimeout(this.close, this.closeTimeout);
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      document.body.classList.add("popup-visible");
    });

    if (this.canClose) {
      this.addCloseListener();
    }

    if (this.id && popupConfig[this.id]?.initOnHash) {
      this.$router.push({ ...this.$route, ...{ hash: "#" + this.id } });
    }
  },
  beforeUnmount() {
    let location = window.location.pathname;

    if (window.location.search) {
      location += window.location.search;
    }

    history.pushState("", document.title, location);
  },
  unmounted() {
    document.body.classList.remove("popup-visible");
  },
};
</script>

<style lang="scss">
@import "@/assets/css/popup.scss";
</style>
