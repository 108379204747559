import favoriteService from "../api/services/favoriteService";

export default () => {
  return {
    namespaced: true,
    state: {
      favorites: [],
      busy: {},
    },

    getters: {
      isFavorite: (state) => (type, id) => state.favorites[type] && state.favorites[type].map((item) => item.entity_id).indexOf(id) > -1,
      getFavorites: (state) => (type) => state.favorites[type] || null,
    },

    mutations: {
      ADD_FAVORITES(state, { type, favorites }) {
        const newFavorites = {};
        newFavorites[type] = favorites;

        state.favorites = {
          ...state.favorites,
          ...newFavorites,
        };
      },

      SET_FAVORITE(state, { type, id }) {
        const favorites = state.favorites[type] || [];

        if (favorites) {
          const i = state.favorites[type].map((item) => item.entity_id).indexOf(id);

          if (i > -1) {
            state.favorites[type].splice(i, 1);
          } else {
            const newFavoriteEntity = {
              entity_id: id,
            };

            favorites.push(newFavoriteEntity);
          }
        }

        const newFavorites = {};
        newFavorites[type] = favorites;

        state.favorites = {
          ...state.favorites,
          ...newFavorites,
        };
      },
      SET_BUSY(state, { type, is_busy }) {
        const busy = {};
        busy[type] = is_busy;

        state.busy = {
          ...state.busy,
          ...busy,
        };
      },
    },

    actions: {
      async pullFavorites({ commit, state }, { type }) {
        if (state.busy[type]) {
          return;
        }

        commit("SET_BUSY", { type, is_busy: true });
        const favorites = await favoriteService.getFavorites(type);

        commit("ADD_FAVORITES", { type, favorites });
        commit("SET_BUSY", { type, is_busy: false });
      },
      async updateFavorite({ commit }, { type, id, rec_id }) {
        await favoriteService.updateFavorite({ type, id, rec_id });

        commit("SET_FAVORITE", { type, id });
      },
    },
  };
};
