import request from "@/api/request";

class PagesService {
  static async sendMessage(subject, message, email, captcha) {
    return await request.post("/pages/contact_us", {
      subject,
      message,
      email,
      captcha,
    });
  }

  static async contentReport(category, name, email, url, is_depicted, is_agreed, reason, captcha) {
    return await request.post("/pages/content_report", {
      category,
      name,
      email,
      url,
      is_depicted,
      is_agreed,
      reason,
      captcha,
    });
  }

  static async get(url, password = null) {
    return await request.get("/pages" + url, { password });
  }

  static async seo() {
    return await request.get("/pages/meta", { url: location.pathname });
  }
}

export default PagesService;
