import { AddBookmark, AddDetailView, AddPurchase, AddRating, ApiClient, SetViewPortion } from "recombee-js-api-client";
import store from "../store/index";
import request from "@/api/request";

let client = null;

const getClient = () => {
  if (!client && process.env.VUE_APP_RECOMBEE_API_KEY) {
    client = new ApiClient(process.env.VUE_APP_RECOMBEE_DB, process.env.VUE_APP_RECOMBEE_API_KEY, { region: "eu-west" });
  }

  return client;
};

const sendRequest = (request) => {
  const client = getClient();

  if (!client) {
    return;
  }

  client.send(request).catch(() => {});
};

const getUserId = () => {
  return store.getters["user/getProfile"]?.id;
};

export const addRating = (itemId, realRating, recommendationId) => {
  const rating = (realRating - 3) / 2;
  sendRequest(
    new AddRating(getUserId(), itemId, rating, {
      cascadeCreate: true,
      recommId: recommendationId,
    }),
  );
};

export const addFavorite = (itemId, recommendationId) => {
  sendRequest(
    new AddBookmark(getUserId(), itemId, {
      cascadeCreate: true,
      recommId: recommendationId,
    }),
  );
};

export const removeFavorite = (itemId) => {
  request.post(`/recommendations/delete_favorite/${itemId}`);
};

export const addGoal = (itemId, recommendationId) => {
  sendRequest(
    new AddPurchase(getUserId(), itemId, {
      cascadeCreate: true,
      recommId: recommendationId,
    }),
  );
};

export const addDetailedView = (itemId, recommendationId) => {
  sendRequest(
    new AddDetailView(getUserId(), itemId, {
      cascadeCreate: true,
      recommId: recommendationId,
    }),
  );
};

export const setViewPortion = (itemId, portion, recommendationId) => {
  const currentDate = new Date();
  const userId = getUserId();
  const sessionId = Math.round(currentDate.getTime() / 1000) + "_" + itemId + "_" + userId;

  sendRequest(
    new SetViewPortion(userId, itemId, portion, {
      sessionId: sessionId,
      cascadeCreate: true,
      recommId: recommendationId,
    }),
  );
};
