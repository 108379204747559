<template>
  <button
    class="btn btn-small tag-btn btn-sm border-dark"
    :class="[
      {
        active: isActive,
      },
    ]"
    @click="onCLick"
  >
    {{ tag["name_" + lang] }}
  </button>
</template>

<script>
import { amplitudeTrack } from "@/utils/amplitude";

export default {
  name: "tag-button",
  props: {
    tag: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["selectTag"],
  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
  },
  methods: {
    onCLick() {
      this.$emit("selectTag", this.tag);

      amplitudeTrack("Tag clicked", {
        "Tag Name": this.tag.name_en,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
