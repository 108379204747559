<template>
  <div class="carousel-video-item" @click="onClick">
    <div class="video-img-wrapper">
      <img v-lazy="thumbnail" class="video-img" :alt="video.title_en" />
    </div>
    <p v-if="showTitle" class="title">{{ video["title_" + lang] }}</p>
  </div>
</template>

<script>
import { getVideoPosterMini } from "@/utils/mediaHelper";
import { mapGetters } from "vuex";

export default {
  name: "carousel-video-item",

  props: {
    video: {
      type: Object,
      require: true,
      default: null,
    },
    showTitle: {
      type: Boolean,
      require: false,
      default: false,
    },
    recommendationSourceId: {
      type: String,
      required: false,
      default: null,
    },
  },

  emits: ["onClick"],

  computed: {
    ...mapGetters("video", ["isPlayable"]),
    lang() {
      return this.$root.$i18n.locale;
    },
    thumbnail() {
      const i = this.video.thumbnails.map((item) => item.is_main).indexOf(true);
      return getVideoPosterMini(this.video.thumbnails[i].file_name);
    },
  },

  methods: {
    onClick() {
      this.$emit("onClick");

      if (!this.isPlayable(this.video)) {
        this.$store.dispatch("user/showSubscriptionPaywall");
        return;
      }

      this.$store.dispatch("player/setTheaterMode", {
        videoId: this.video.id,
        recId: this.video.recommendation_source_id || this.recommendationSourceId,
      });
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
