import landingService from "@/api/services/landingService";

export default () => {
  return {
    namespaced: true,
    state: {
      landingPages: {},
    },
    getters: {
      getLandingPageById: (state) => {
        return (id) => state.landingPages[id];
      },
      getDefaultLandingPage: (state) => {
        return (lang) => {
          const landingLang = lang === "de" ? lang : "en";

          for (const id in state.landingPages) {
            const landing = state.landingPages[id];

            if (landing.default) {
              if (landing.language === landingLang) {
                return landing;
              }
            }
          }

          return null;
        };
      },
    },
    mutations: {
      ADD_LANDING(state, landing) {
        const newLanding = {};
        newLanding[landing.id] = landing;

        state.landingPages = {
          ...state.landingPages,
          ...newLanding,
        };
      },
    },
    actions: {
      async getLandingPage({ commit }, payload) {
        const landing = await landingService.getLandingPage(payload);
        commit("ADD_LANDING", landing);

        return landing;
      },
    },
  };
};
