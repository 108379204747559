import PagesService from "../api/services/pagesService";

export default () => {
  return {
    namespaced: true,
    state: {
      pages: {},
    },
    getters: {
      getCustomPage(state) {
        return (url) => state.pages[url];
      },
    },
    mutations: {
      ADD_CUSTOM_PAGE(state, { url, page }) {
        state.pages[url] = page;
      },
    },
    actions: {
      async getCustomPage({ commit }, url, password) {
        const page = await PagesService.get(url, password);

        if (page && !page.has_password) {
          commit("ADD_CUSTOM_PAGE", { url, page });
        }

        return page;
      },
    },
  };
};
