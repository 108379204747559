import { deleteCookie, getCookie } from "./cookies";

export default (name, remove = true) => {
  const value = getCookie(name);

  if (remove) {
    deleteCookie(name);
  }

  return JSON.parse(value);
};
