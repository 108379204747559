<template>
  <div class="preloader" :class="`lang-${lang}`">
    <div class="spinner-blink" />
  </div>
</template>

<script>
export default {
  name: "preloader",
  computed: {
    lang() {
      return this.$root.$i18n.locale;
    },
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
