import store from "../../store/index";
import { setVisitUid, VISIT_UID_KEY } from "@/utils/storage";
import { getCookie } from "@/utils/cookies";

export default async () => {
  const visitCookie = getCookie(VISIT_UID_KEY);

  if (visitCookie) {
    setVisitUid(visitCookie);
  }

  if (!store.getters["visit/getVisit"]) {
    await store.dispatch("visit/trackVisit");
  }
};
