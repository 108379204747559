import Bugsnag from "@bugsnag/js";
import BugsnagPerformance from "@bugsnag/browser-performance";
import BugsnagPluginVue from "@bugsnag/plugin-vue";
import store from "../store/index";
import { VueRouterRoutingProvider } from "@bugsnag/vue-router-performance";
import router from "@/router/router";

const { VUE_APP_ENV, VUE_APP_VERSION, VUE_APP_BUGSNAG_API_KEY } = process.env;
const suppressedErrors = [
  "cancel navigation due to redirect to old site",
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.", // mostly in imlive chats, it doesn't depend on us
  "Exception invoking getStackDelegated",
  "'i' is not defined",
  "ReferenceError: i is not defined",
  "ReferenceError: Can't find variable: i",
  "V", // dont report strange google errors
  "Failed to fetch",
  "fetch failed",
  "Not in fullscreen mode",
  "The video is not fullscreen",
  "Fullscreen request denied",
  "Cannot read properties of null (reading 'getBoundingClientRect')",
  "null is not an object (evaluating 'e.value.getBoundingClientRect')",
];

const options = {
  apiKey: VUE_APP_BUGSNAG_API_KEY,
  appVersion: VUE_APP_VERSION,
  releaseStage: VUE_APP_ENV,
  enabledReleaseStages: ["production", "staging"],
};

BugsnagPerformance.start({
  ...options,
  routingProvider: new VueRouterRoutingProvider(router),
});

Bugsnag.start({
  ...options,
  autoTrackSessions: false,
  plugins: [new BugsnagPluginVue()],
  onError: async function (event) {
    const message = event.originalError?.message || event.originalError;

    if (suppressedErrors.indexOf(message) > -1) {
      return false;
    }

    if (event.originalError?.status?.status >= 400) {
      return false;
    }

    const profile = store.getters["user/getProfile"];

    if (profile) {
      event.setUser(profile.id);
    }

    event.addMetadata("error", {
      message,
      status: event.originalError?.status,
    });
  },
});

export default Bugsnag;
