import request from "@/api/request";

class RatingService {
  static async getRatings(type) {
    return await request.get(`/ratings/${type}`);
  }

  static async updateRating({ type, id, rating, rec_id = null }) {
    return await request.put(`/ratings/${type}`, { id, rating, rec_id });
  }
}

export default RatingService;
