import commentsService from "../api/services/commentsService";

export default () => {
  return {
    namespaced: true,
    state: {
      comments: [],
      total: 0,
    },

    getters: {
      getComments: (state) => state.comments,
      getTotal: (state) => state.total,
    },

    mutations: {
      ADD_COMMENTS(state, { comments }) {
        const existingComments = state.comments;

        state.comments = [...existingComments, ...comments];
      },
      CLEAR_COMMENTS(state) {
        state.comments = [];
      },
      ADD_COMMENT(state, { comment, parent_id }) {
        const comments = state.comments;

        if (parent_id) {
          const i = comments.map((item) => item.id).indexOf(parent_id);
          const parentComment = comments[i];

          parentComment.childs.unshift(comment);
        } else {
          comments.unshift(comment);
        }
      },

      DELETE_COMMENT(state, id) {
        const i = state.comments.map((comment) => comment.id).indexOf(id);

        if (i !== -1) {
          state.comments.splice(i, 1);
        } else {
          state.comments.map((comment) => {
            const j = comment.childs.map((child) => child.id).indexOf(id);

            if (j !== -1) {
              comment.childs.splice(j, 1);
            }
          });
        }
      },

      SET_TOTAL(state, total) {
        state.total = total;
      },

      UPDATE_LIKE_COUNT(state, { commentId, increase }) {
        const i = state.comments.map((comment) => comment.id).indexOf(commentId);

        if (i !== -1) {
          if (increase) {
            state.comments[i].like_count++;
          } else {
            state.comments[i].like_count--;
          }
        } else {
          state.comments.map((comment) => {
            const j = comment.childs.map((child) => child.id).indexOf(commentId);

            if (j !== -1) {
              if (increase) {
                comment.childs[j].like_count++;
              } else {
                comment.childs[j].like_count--;
              }
            }
          });
        }
      },
    },

    actions: {
      async loadComments({ commit }, { id, offset, limit, order }) {
        const response = await commentsService.getComments(id, offset, limit, order);

        commit("ADD_COMMENTS", { comments: response.items });
        commit("SET_TOTAL", response.total);
      },
      async clearComments({ commit }) {
        commit("CLEAR_COMMENTS");
        commit("SET_TOTAL", 0);
      },
      async postComment({ commit, dispatch }, { id, text, parent_id = null }) {
        try {
          const comment = await commentsService.postComment(id, text, parent_id);
          commit("ADD_COMMENT", { comment, parent_id });
          dispatch("video/increaseCommentCount", { id }, { root: true });
        } catch (error) {
          //
        }
      },
      async deleteComment({ commit }, { id }) {
        try {
          await commentsService.deleteComment(id);
          commit("DELETE_COMMENT", id);
        } catch (error) {
          //
        }
      },
      async updateLikeCount({ commit, rootGetters }, { id }) {
        const isLiked = rootGetters["like/isLiked"]("comment", id);

        commit("UPDATE_LIKE_COUNT", { commentId: id, increase: isLiked });
      },
    },
  };
};
