export const insertScript = (src, attributes = {}) => {
  return new Promise((resolve, reject) => {
    let script = null;

    if (src) {
      script = document.querySelector('[src="' + src + '"]');
    }

    if (!script && attributes.id) {
      script = document.getElementById(attributes.id);
    }

    if (!script) {
      script = document.createElement("script");
      script.setAttribute("src", src);

      script.onload = () => resolve();
      script.onerror = () => reject();

      for (const key in attributes) {
        script.setAttribute(key, attributes[key]);
      }

      const s = document.getElementsByTagName("script");
      const ls = s[s.length - 1];
      ls.parentNode.insertBefore(script, ls.nextSibling);
    } else {
      resolve();
    }
  });
};

export const isIframe = () => {
  try {
    return window.location.hostname !== window.parent.location.hostname;
  } catch (e) {
    // some browsers can still report iframe while its not really iframe, so to make sure we need embed site with ?iframe param
    return window.location.search.indexOf("iframe") > -1;
  }
};
