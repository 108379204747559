import pagesService from "@/api/services/pagesService";
import { getTld } from "@/utils/urlHelper";

export const metaHook = async (to, from, failure) => {
  if (failure) {
    return;
  }

  const res = await pagesService.seo();
  const mandatoryMeta = ["title", "description", "keywords"];
  const metas = ["robots", ...mandatoryMeta];
  metas.forEach((key) => {
    let meta = document.querySelector(`meta[name="${key}"]`);
    let value = res[key];

    // todo - think how to customize more general
    if (!value && key === "title" && to.meta.title) {
      value = to.meta.title();
    } else if (!value && key === "robots" && to.fullPath.indexOf("lecker_blog") > -1 && getTld() !== "ch") {
      value = "noindex";
    }

    if (!value) {
      if (meta && !mandatoryMeta.includes(key)) {
        document.head.removeChild(meta);
      }

      return;
    }

    if (!meta) {
      meta = document.createElement("meta");
      document.head.appendChild(meta);
      meta.setAttribute("name", key);
    }

    meta.setAttribute("content", value);
  });
  let canonicalLink = document.querySelector("link[rel=canonical]");

  if (!canonicalLink) {
    canonicalLink = document.createElement("link");
    document.head.appendChild(canonicalLink);
    canonicalLink.setAttribute("rel", "canonical");
  }

  canonicalLink.setAttribute("href", location.origin + location.pathname);
};
