import { getTitle } from "@/utils/title";
import { LAYOUT_LADIES, LAYOUT_LADIES_V2 } from "@/utils/variables";

export default [
  {
    path: "/",
    component: () => import(/* webpackChunkName: 'ladies-home' */ "@/pages/ladies-home"),
    meta: {
      title: () => getTitle("Ersties"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/ladies",
    component: () => import(/* webpackChunkName: 'ladies' */ "@/pages/ladies"),
    meta: {
      title: () => getTitle("Ersties"),
      layout: LAYOUT_LADIES,
    },
  },
  {
    path: "/contact-us",
    component: () => import(/* webpackChunkName: 'contact-us' */ "@/pages/contact-us"),
    meta: {
      title: () => getTitle("Contact Us"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/agb",
    component: () => import(/* webpackChunkName: 'agb-page' */ "@/pages/agb"),
    meta: {
      title: () => getTitle("2257 Notice / Legal"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/report",
    component: () => import(/* webpackChunkName: 'report' */ "@/pages/report"),
    meta: {
      title: () => getTitle("Content Removal"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/dmca",
    component: () => import(/* webpackChunkName: 'dmca' */ "@/pages/dmca"),
    meta: {
      title: () => getTitle("DMCA"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/prohibited_content",
    component: () => import(/* webpackChunkName: 'prohibited-content' */ "@/pages/prohibited-content"),
    meta: {
      title: () => getTitle("Prohibited Content"),
      layout: LAYOUT_LADIES_V2,
    },
  },
  {
    path: "/:pathMatch(.*)*",
    component: { template: "404 Not Found" },
    meta: {
      title: () => getTitle("Not Found"),
      layout: LAYOUT_LADIES,
    },
  },
];
