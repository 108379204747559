import messageService from "@/api/services/messageService";

export default () => {
  return {
    namespaced: true,
    state: {
      newMessageCount: 0,
      newMessage: null,
      lastMessageTimestamp: null,
    },
    getters: {
      getNewMessageCount(state) {
        return state.newMessageCount;
      },
      getNewMessage(state) {
        return state.newMessage;
      },
      getLastMessageTimestamp(state) {
        return state.lastMessageTimestamp;
      },
    },
    mutations: {
      SET_NEW_MESSAGE_COUNT(state, count) {
        state.newMessageCount = count;
      },
      SET_NEW_MESSAGE(state, message) {
        if (message === null) {
          return;
        }

        state.newMessage = message;
      },
      SET_LAST_MESSAGE_TIMESTAMP(state, timestamp) {
        state.lastMessageTimestamp = timestamp;
      },
    },
    actions: {
      async getNewMessages({ commit }, timestamp = null) {
        const newMessages = await messageService.getNewMessages(timestamp);

        commit("SET_NEW_MESSAGE_COUNT", newMessages.count);
        commit("SET_NEW_MESSAGE", newMessages.message);
        commit("SET_LAST_MESSAGE_TIMESTAMP", newMessages.last_popup_mail_timestamp);
      },
      closeMessagePopup({ commit }) {
        commit("SET_NEW_MESSAGE", false);
      },
    },
  };
};
