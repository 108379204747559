<template>
  <div
    ref="likeButton"
    class="like"
    :class="[
      {
        liked: liked || isStatic,
        static: isStatic,
        counter: count,
        'lightbox-like': inLightbox,
        hovered: likeHover,
      },
      theme,
      icon,
    ]"
    @click="click"
    @mouseover="onHover"
    @mouseleave="onLeave"
  >
    <template v-if="count">{{ count }}</template>
  </div>
  <app-tooltip v-if="showTooltip" placement="bottom" :content="label" :target="() => $refs.likeButton" />
</template>

<script>
import AppTooltip from "../tooltip";
import { mapGetters } from "vuex";

export default {
  name: "like",
  components: { AppTooltip },
  props: {
    type: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: false,
      default: null,
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
    inLightbox: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: "default",
      validator: (value) => {
        return ["default", "dark"].includes(value);
      },
    },
    icon: {
      type: String,
      required: false,
      default: "rose",
      validator: (value) => {
        return ["rose", "heart"].includes(value);
      },
    },
    showTooltip: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["onLikeUpdate"],
  data() {
    return {
      likeHover: false,
    };
  },
  computed: {
    ...mapGetters("like", ["isLiked", "getLikes"]),
    liked() {
      return this.isLiked(this.type, this.id);
    },
    label() {
      if (this.liked) {
        return this.$t("Do not like it?");
      }

      return this.$t("Like it?");
    },
  },
  methods: {
    loadState() {
      if (this.getLikes(this.type) === null) {
        this.$store.dispatch("like/pullLikes", { type: this.type });
      }
    },
    async click() {
      if (this.isStatic) {
        return;
      }

      await this.$store.dispatch("like/updateLike", { type: this.type, id: this.id });
      this.$emit("onLikeUpdate");
      this.likeHover = false;
    },
    onHover() {
      this.likeHover = true;
    },
    onLeave() {
      this.likeHover = false;
    },
  },
  mounted() {
    this.loadState();
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
