/**
 * Default data store.
 * Instantiated in src/main.js
 **/

import { createStore } from "vuex";
import model from "./model";
import company from "./company";
import gallery from "./gallery";
import user from "./user";
import camera from "./camera";
import popup from "./popup";
import rating from "./rating";
import favorite from "./favorite";
import playdate from "./playdate";
import update from "@/store/update";
import video from "./video";
import message from "./message";
import customPage from "./customPage";
import layout from "./layout";
import landing from "./landing";
import modal from "./modal";
import visit from "./visit";
import role from "./role";
import feature from "@/store/feature";
import feed from "@/store/feed";
import comments from "@/store/comments";
import like from "@/store/like";
import player from "@/store/player";
import blog from "@/store/blog";

const debug = process.env.NODE_ENV !== "production";

export default createStore({
  strict: debug,
  modules: {
    company: company(),
    model: model(),
    gallery: gallery(),
    user: user(),
    update: update(),
    camera: camera(),
    popup: popup(),
    rating: rating(),
    favorite: favorite(),
    playdate: playdate(),
    video: video(),
    message: message(),
    customPage: customPage(),
    layout: layout(),
    landing: landing(),
    modal: modal(),
    visit: visit(),
    role: role(),
    feature: feature(),
    feed: feed(),
    comments: comments(),
    like: like(),
    player: player(),
    blog: blog(),
  },
});
