import { ApiError } from "@/api/apiError";
import { getVisitUid, loadAccessToken } from "@/utils/storage";
import { getApiHost, getTld } from "@/utils/urlHelper";
import { setVisitUid } from "../utils/storage";

function stripObject(obj) {
  return Object.keys(obj)
    .filter((k) => obj[k] != null)
    .reduce((a, k) => ({ ...a, [k]: obj[k] }), {});
}

class API {
  constructor() {
    this.root = getApiHost();
    this.retryLimit = 2;
  }

  async _request(method, path, params, { signal = null, rawParams = false, count = 0, errorMessage = "Retry limit reached" } = {}) {
    const root = this.root;
    const url = new URL(root + path);
    let requestBody = null;

    if (rawParams) {
      requestBody = params;
    } else if (typeof params === "object") {
      if (method === "GET") {
        url.search = new URLSearchParams(stripObject(params)).toString();
      } else {
        requestBody = JSON.stringify(params);
      }
    }

    if (count > this.retryLimit) {
      return Promise.reject(
        new ApiError(errorMessage, {
          errorMessage: {
            code: "MaxRetries",
            message: errorMessage,
            path,
          },
        }),
      );
    }

    let headers = {
      Accept: "application/json",
      "X-Source-Tld": getTld(),
      "X-Visit-Uid": getVisitUid(),
    };

    if (!(requestBody instanceof FormData) && !(requestBody instanceof URLSearchParams)) {
      headers["Content-Type"] = "application/json";
    }

    const token = loadAccessToken();
    headers = token ? { ...headers, Authorization: `Bearer ${token}` } : headers;

    let response = null;

    try {
      response = await fetch(url.toString(), {
        method: method,
        headers: headers,
        body: requestBody,
        credentials: "include",
        mode: "cors",
        cache: "no-store",
        referrerPolicy: "unsafe-url",
        signal,
      });
    } catch (e) {
      if (e.name === "AbortError") {
        return null;
      }

      return await this._request(method, path, params, {
        signal,
        rawParams,
        count: count + 1,
        errorMessage: "fetch failed",
      });
    }

    if (response.status === 204) {
      return {};
    }

    const ct = response.headers.get("content-type");

    if (!ct || !ct.includes("application/json")) {
      const text = await response.text();
      throw new ApiError(text, {
        status: response.status,
      });
    }

    const visitUid = response.headers.get("X-Visit-Uid");

    if (visitUid) {
      setVisitUid(visitUid);
    }

    const body = await response.json();

    if (response.status >= 500) {
      return await this._request(method, path, params, {
        signal,
        rawParams,
        count: count + 1,
        errorMessage: body.message || "unknown api error",
      });
    } else if (response.status >= 400) {
      throw new ApiError(response.message, {
        status: response.status,
        ...body,
      });
    }

    return body;
  }
}

const api = new API();

export default {
  get: async (path, params, signal = null) => api._request("GET", path, params, { signal }),
  post: async (path, params, rawParams = false, signal = null) => api._request("POST", path, params, { rawParams, signal }),
  put: async (path, params, rawParams = false, signal = null) => api._request("PUT", path, params, { rawParams, signal }),
  patch: async (path, params, rawParams = false, signal = null) => api._request("PATCH", path, params, { rawParams, signal }),
  delete: async (path, params, rawParams = false, signal = null) => api._request("DELETE", path, params, { rawParams, signal }),
};
