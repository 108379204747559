import request from "@/api/request";

class LikeService {
  static async getLikes(type) {
    return await request.get(`/likes/${type}`);
  }

  static async updateLike({ type, id }) {
    return await request.put(`/likes/${type}/${id}`);
  }
}

export default LikeService;
