import trackMiddleware from "@/router/middleware/trackMiddleware";
import { loadAccessToken, removeAccessToken } from "@/utils/storage";

export default [
  async ({ to }) => {
    const accessToken = loadAccessToken();

    if (!accessToken) {
      return;
    }

    removeAccessToken();

    const restrictedHosts = JSON.parse(process.env.VUE_APP_RESTRICTED_MEMBERAREA_HOSTS);
    const host = window.location.host.split(".").slice(-2).join(".");

    if (restrictedHosts.includes(host)) {
      await new Promise(() => {
        window.location = process.env.VUE_APP_MEMBERAREA_HOST + to.fullPath + "?authToken=" + accessToken;
      });
    }
  },
  trackMiddleware,
];
