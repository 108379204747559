import { createI18n } from "vue-i18n";
import { setLanguage } from "@/utils/storage";
import userService from "@/api/services/userService";
import store from "@/store/index";

const de = require(/* webpackChunkName: "lang" */ `./locales/de.json`);
const fr = require(/* webpackChunkName: "lang" */ `./locales/fr.json`);

const i18n = createI18n({
  silentTranslationWarn: true,
  silentFallbackWarn: true,
  warnHtmlInMessage: "off",
  locale: "en",
  messages: {
    de,
    fr,
  },
});

export default i18n;

export const changeLanguage = (neededLang) => {
  const lang = neededLang ? neededLang : i18n.global.locale === "de" ? "en" : "de";

  document.body.classList.remove("lang_en", "lang_de");
  document.body.classList.add("lang_" + lang);

  if (i18n.global.locale === lang) {
    return;
  }

  if (store.getters["user/getProfile"]) {
    userService.switchLang(lang);
  }

  i18n.global.locale = lang;
  setLanguage(lang);
};
