import request from "@/api/request";
import i18n from "../../i18n";

class CameraService {
  static async getList({ count, page, order, filters }) {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/list`, { count, page, order, filters });
  }

  static async searchCamera({ term }) {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/search`, { term });
  }

  static async getProfile(name) {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/profile/${name}`);
  }

  static async getShortProfilesByIds(ids) {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/short_profiles`, { ids: ids.join(",") });
  }

  static async getOnlineShortList() {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/online_short_list`);
  }

  static async getFreechatCameras() {
    return request.get("/cameras/freechat_list");
  }

  static async getChat({ name }) {
    const lang = i18n.global.locale;
    return request.get(`/cameras/${lang}/${name}/chat`);
  }

  static async closeChat({ name }) {
    await request.put(`/cameras/${name}/chat/close`);
  }

  static async getFreeChat({ name }) {
    return request.get(`/cameras/${name}/freechat`);
  }

  static async addFreeChat({ name }) {
    return request.post(`/cameras/${name}/freechat`);
  }

  static async pingFreeChat({ name, id }) {
    await request.put(`/cameras/${name}/freechat/ping/${id}`);
  }

  static async finishFreeChat({ name, id }) {
    await request.put(`/cameras/${name}/freechat/finish/${id}`);
  }

  static async getPreview({ name }) {
    return request.get(`/cameras/${name}/preview`);
  }

  static async updateStatus(name) {
    const lang = i18n.global.locale;
    return request.post(`/cameras/${lang}/${name}/update_status`);
  }
}

export default CameraService;
