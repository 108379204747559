import router from "@/router/router";
import { VIDEO_SEARCH_CATEGORY_TAG } from "@/utils/variables";
import { loadTld } from "@/utils/storage";
import store from "@/store";
import i18n from "@/i18n";

export const updateUrl = async (route, args, newPath, hash = null) => {
  const params = new URLSearchParams(route.query);

  Object.keys(args).map((k) => {
    const v = args[k];

    if (v === null) {
      params.delete(k);
    } else {
      params.set(k, v);
    }
  });
  let url = newPath || route.path;

  if (params.toString() !== "") {
    url += "?" + params.toString();
  }

  if (hash !== null) {
    url += hash;
  }

  if (url !== route.fullPath) {
    await router.push(url);
  }
};

export const updateVideoTags = async (id, label, type) => {
  const route = router.currentRoute.value;
  const category = route.query.category || null;
  let ids = route.query.ids && category === VIDEO_SEARCH_CATEGORY_TAG ? route.query.ids.split(",").map((id) => parseInt(id)) : [];
  let query = route.query.query || "";

  if (ids.includes(id)) {
    ids = ids.filter((e) => e !== id);
    query = query.replace(label, "");
  } else {
    ids.push(id);
    query += ` ${label}`;
  }

  await updateUrl(
    route,
    {
      category: ids.length > 0 ? VIDEO_SEARCH_CATEGORY_TAG : null,
      ids: ids.length > 0 ? ids : null,
      page: null,
      query: query.trim(),
      search_type: type || VIDEO_SEARCH_CATEGORY_TAG,
    },
    "/videos",
  );
};

export const getApiHost = () => process.env.VUE_APP_API_HOST;

export const getTld = () => loadTld() || window.location.origin.split(".").pop();

export const getAffiliateUrl = () => {
  return store.getters["visit/getVisit"]?.country.toLowerCase() === "de"
    ? "https://www.vxcash.net/"
    : "https://affiliates.ersties.com/signup-request";
};

export const getBlogUrl = () => {
  return i18n.global.locale === "de" ? "/lecker_blog" : "/blog";
};
