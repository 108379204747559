<template>
  <div
    v-if="profile.download_token && !video.is_vr && video.is_downloadable && profile.can_download_content"
    v-click-outside="close"
    class="download-video-dropdown"
    :class="[theme, { long }]"
  >
    <span ref="downloadDropdown" @click="showDropdown = !showDropdown">
      <template v-if="long"> {{ $t("Download") }}</template>
    </span>
    <app-tooltip v-if="!long" :target="() => $refs.downloadDropdown" placement="right-end" :show="!showDropdown">
      {{ $t("Click to save the video in .mp4 format.") }}
    </app-tooltip>
    <ul v-show="showDropdown" class="dropdown-menu">
      <li v-for="(url, stream) in video.download_urls" :key="stream">
        <a :href="`${host}${url}?token=${profile.download_token}`">
          {{ stream }}
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import AppTooltip from "../tooltip";
import { mapGetters } from "vuex";
import { getApiHost } from "@/utils/urlHelper";
export default {
  name: "video-download",
  components: { AppTooltip },
  props: {
    video: {
      type: Object,
      required: true,
    },
    long: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: "default",
      validator: (value) => {
        return ["default", "dark"].includes(value);
      },
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
  computed: {
    ...mapGetters("user", {
      profile: "getProfile",
    }),
    host() {
      return getApiHost();
    },
  },
  methods: {
    close() {
      this.showDropdown = false;
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
