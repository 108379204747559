<template>
  <div
    ref="favouriteButton"
    class="favorite-button btn btn-blue border-dark p-1"
    :class="[
      {
        added: isFav,
        short: short,
        'w-100': !short,
      },
      theme,
    ]"
    @click="onClick"
    @mouseover="onHover"
    @mouseleave="onLeave"
  >
    <span v-if="!short">{{ label }}</span>
  </div>
  <app-tooltip v-if="short" :content="label" placement="bottom" :target="() => $refs.favouriteButton" />
</template>

<script>
import { mapGetters } from "vuex";
import AppTooltip from "../tooltip";
import { addFavorite, removeFavorite } from "@/utils/recommendor";

export default {
  name: "favorite",
  components: { AppTooltip },
  props: {
    type: {
      type: String,
      required: true,
    },
    entityId: {
      type: Number,
      required: true,
    },
    short: {
      type: Boolean,
      required: false,
      default: false,
    },
    theme: {
      type: String,
      required: false,
      default: "default",
      validator: (value) => {
        return ["default", "dark"].includes(value);
      },
    },
    recommendationItemId: {
      type: String,
      required: false,
      default: null,
    },
    recommendationSourceId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isHovered: false,
    };
  },
  computed: {
    ...mapGetters("favorite", ["isFavorite", "getFavorites"]),
    isFav() {
      return this.isFavorite(this.type, this.entityId) || null;
    },
    label() {
      if (this.isHovered && this.isFav) {
        return this.$t("Remove from favourites");
      } else if (this.isFav) {
        return this.$t("Added to my favourites");
      }

      return this.$t("Add to my favourites");
    },
  },
  methods: {
    async onClick() {
      await this.$store.dispatch("favorite/updateFavorite", {
        type: this.type,
        id: this.entityId,
      });

      if (this.recommendationItemId) {
        this.isFav ? addFavorite(this.recommendationItemId, this.recommendationSourceId) : removeFavorite(this.recommendationItemId);
      }
    },
    onHover() {
      this.isHovered = true;
    },
    onLeave() {
      this.isHovered = false;
    },
  },
  mounted() {
    if (this.getFavorites(this.type) === null) {
      this.$store.dispatch("favorite/pullFavorites", { type: this.type });
    }
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
