<template>
  <div class="app-carousel-pagination">
    <div class="app-carousel-dot-container">
      <button
        v-for="(page, index) in count"
        :key="`${page}_${index}`"
        class="app-carousel-dot"
        :class="{ 'app-carousel-dot--active': index === current }"
        :style="dotStyle"
        @click="goToPage(index)"
      ></button>
    </div>
  </div>
</template>

<script>
export default {
  name: "app-carousel-pagination",
  props: {
    current: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
  },
  emits: ["paginationClick"],
  data() {
    return {
      maxPaginationDotCount: 3,
      paginationPadding: 4,
      paginationSize: 8,
    };
  },
  computed: {
    dotStyle() {
      const eachDotsWidth = this.paginationSize + this.paginationPadding * 2;
      const maxReverse = this.count - this.maxPaginationDotCount;
      const translateAmount =
        this.current > maxReverse
          ? maxReverse
          : this.current <= this.maxPaginationDotCount / 2
            ? 0
            : this.current - Math.ceil(this.maxPaginationDotCount / 2) + 1;
      const transformWidth = 0 - eachDotsWidth * translateAmount;
      return {
        "-webkit-transform": `translate3d(${transformWidth}px,0,0)`,
        transform: `translate3d(${transformWidth}px,0,0)`,
      };
    },
  },
  methods: {
    goToPage(index) {
      this.$emit("paginationClick", index);
    },
  },
};
</script>

<style scoped lang="scss">
@import "./index";
</style>
