import { createApp } from "vue";
import store from "./store";
import App from "./app.vue";
import router from "@/router/router";
import i18n from "@/i18n";
import vClickOutside from "click-outside-vue3";
import vModal from "./directives/vModal";
import VueLazyload from "vue-lazyload";
import { VueMasonryPlugin } from "vue-masonry";
import MiddlewarePlugin from "vue-router-middleware-plugin";
import appMiddleware from "./router/middleware/appMiddleware";
import Bugsnag from "./utils/bugsnag";
import "./utils/gtm.js";
import "bootstrap/js/src/collapse.js";
import "bootstrap/js/src/modal.js";
import "bootstrap/js/src/dropdown.js";
import { getTld } from "@/utils/urlHelper";
import ioMiddleware from "@/router/middleware/ioMiddleware";

const app = createApp(App);
app.config.compilerOptions.whitespace = "preserve";

app
  .use(VueMasonryPlugin)
  .use(VueLazyload, {
    lazyComponent: true,
    silent: true,
  })
  .use(vClickOutside)
  .use(router)
  .use(store)
  .use(i18n)
  .use(Bugsnag.getPlugin("vue"));

app.use(MiddlewarePlugin, {
  router,
  middleware: getTld() !== "io" ? appMiddleware : ioMiddleware,
});

app.directive("modal", vModal);

app.mount("#app");
