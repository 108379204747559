import RoleService from "../api/services/roleService";
import { getCookie, setCookie } from "@/utils/cookies";
import { DISABLED_ROLES_COOKIE } from "@/utils/variables";

export default () => {
  return {
    namespaced: true,
    state: {
      roles: [],
      disabledRoles: [],
    },

    getters: {
      getAll: (state) => state.roles,
      getDisabled: (state) => state.disabledRoles,
      hasRole: (state) => (token) => {
        const enabled = [...state.roles];

        state.disabledRoles.forEach((token) => {
          enabled.splice(enabled.map((role) => role.token).indexOf(token), 1);
        });

        return enabled.map((role) => role.token).indexOf(token) !== -1;
      },
    },

    mutations: {
      SET_ROLES(state, list) {
        state.roles = list;
      },
      SET_DISABLED_LIST(state, list) {
        state.disabledRoles = list;
      },
      TOGGLE_ROLE(state, token) {
        const disabled = state.disabledRoles || [];

        if (!disabled.includes(token)) {
          disabled.push(token);
        } else {
          disabled.splice(disabled.indexOf(token), 1);
        }

        state.disabledRoles = disabled;
        setCookie(DISABLED_ROLES_COOKIE, disabled.join(","), 14);
      },
    },

    actions: {
      async load({ commit }) {
        const list = await RoleService.getRoles();
        commit("SET_ROLES", list);

        const disabled = getCookie(DISABLED_ROLES_COOKIE);
        const disabledRoles = disabled ? disabled.split(",") : [];
        commit("SET_DISABLED_LIST", disabledRoles);
      },
      toggleRole({ commit }, token) {
        commit("TOGGLE_ROLE", token);
      },
    },
  };
};
