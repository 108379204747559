export default {
  beforeMount(el, binding) {
    let target = binding.value;

    if (Object.keys(binding.modifiers).length > 0) {
      [target] = Object.keys(binding.modifiers);
    }

    el.setAttribute("data-bs-toggle", "modal");
    el.setAttribute("data-bs-target", `#${target}`);
  },
};
