import { getTld } from "@/utils/urlHelper";
import store from "@/store";
import { getCookie } from "@/utils/cookies";
import { APPROVED_AGE_COOKIE } from "@/utils/variables";

export const canShowAgeApproval = () => {
  if (getCookie(APPROVED_AGE_COOKIE)) {
    return false;
  }

  if (store.getters["user/isLoggedIn"]) {
    return false;
  }

  const tld = getTld();

  if (["net", "de", "ch", "com", "tv"].indexOf(tld) === -1) {
    return false;
  }

  const visit = store.getters["visit/getVisit"];
  const directOrganicVisit = !visit.campaign && !document.referrer;

  return !(["tv", "com"].indexOf(tld) > -1 && !directOrganicVisit);
};
