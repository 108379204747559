export const getCookie = (name) => {
  const v = document.cookie.match("(^|;) ?" + name + "=([^;]*)(;|$)");
  return v ? decodeURIComponent(decodeURI(v[2])) : null;
};

export const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + 24 * 60 * 60 * 1000 * days);

  const full = document.domain.match(/[^.]*\.[^.]*$/);
  const domain = "domain=" + (full ? full[0] : "") + ";";

  document.cookie = name + "=" + value + ";path=/;" + domain + "expires=" + d.toGMTString();
};

export const deleteCookie = (name) => {
  setCookie(name, "", -1);
};
