export default (player, seekStep) => {
  const controls = player?.controlBar;

  const seekBackwardHandler = () => {
    player.currentTime(player.currentTime() - seekStep);
  };

  const seekForwardHandler = () => {
    player.currentTime(player.currentTime() + seekStep);
  };

  const seekBackButton = controls.addChild(
    "button",
    {
      clickHandler: () => seekBackwardHandler(),
    },
    8,
  );
  seekBackButton.addClass("seek-icon icon icon-seek-backward-10");

  const seekForwardButton = controls.addChild(
    "button",
    {
      clickHandler: () => seekForwardHandler(),
    },
    9,
  );
  seekForwardButton.addClass("seek-icon icon icon-seek-forward-10");

  const touchOverlay = player.touchOverlay;

  if (touchOverlay) {
    const seekBackMobileButton = touchOverlay.addChild("button", {
      clickHandler: () => seekBackwardHandler(),
    });
    seekBackMobileButton.addClass("seek-icon-mobile icon icon-seek-backward-10");

    const seekForwardMobileButton = touchOverlay.addChild("button", {
      clickHandler: () => seekForwardHandler(),
    });
    seekForwardMobileButton.addClass("seek-icon-mobile icon icon-seek-forward-10");
  }
};
