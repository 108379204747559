import request from "@/api/request";

class UserService {
  static async switchLang(lang) {
    await request.post("/user/lang", { lang });
  }

  static async changePlaydateDescriptionBlock(hideDescriptionBlock) {
    return await request.post("/user/playdate_description_block_hidden", { hideDescriptionBlock });
  }

  static async changeUpcomingPlaydateBlock(hide) {
    return await request.post("/user/upcoming_playdate_block_hidden", { hide });
  }

  static async getProfile() {
    return await request.get("/user/profile");
  }

  static async changePassword(password, newPassword) {
    return await request.post("/user/change_password", {
      password,
      newPassword,
    });
  }

  static async changeUserData(firstName, lastName, street, house, zip, city, country) {
    return await request.post("/user/change_personal_data", {
      firstName,
      lastName,
      street,
      house,
      zip,
      city,
      country,
    });
  }

  static async changeUserName(userName, chatName, password) {
    return await request.post("/user/change_username", {
      userName,
      chatName,
      password,
    });
  }

  static async changeEmail(newEmail, password = null) {
    return await request.post("/user/change_email", {
      newEmail,
      password,
    });
  }

  static async changeNotifications(hasPlaydateNotification, hasCameraMailNotification) {
    return await request.post("/user/change_notification_settings", {
      hasPlaydateNotification,
      hasCameraMailNotification,
    });
  }

  static async getPaymentHistory(page) {
    return await request.get("/user/get_payment_history", {
      page,
    });
  }

  static async subscribeNewsletter() {
    await request.get("/user/subscribe_newsletter");
  }

  static async unsubscribeCameraMails() {
    await request.get("/user/unsubscribe_mails");
  }

  static async verificationEmail() {
    return await request.post("/user/verification_email");
  }

  static async validateUsername(username) {
    return await request.post("/user/validate_username", { username });
  }

  static async validateEmail(email) {
    return await request.post("/user/validate_email", { email });
  }

  static async changeAvatar(avatar) {
    return await request.post("/user/change_avatar", { avatar });
  }
}

export default UserService;
