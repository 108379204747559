import playdateService from "../api/services/playdateService";

export default () => {
  return {
    namespaced: true,
    state: {
      cameras: [],
    },

    getters: {
      getProfile: (state) => (id) => state.cameras[id],
    },

    mutations: {
      SET_CAMERA(state, camera) {
        const newCamera = {};
        newCamera[camera.id] = camera;

        state.cameras = {
          ...state.cameras,
          ...newCamera,
        };
      },
    },

    actions: {
      async getProfile({ commit }, { id }) {
        const response = await playdateService.getProfile(id);
        commit("SET_CAMERA", response);

        return response;
      },
    },
  };
};
