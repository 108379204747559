<template>
  <div class="box-backgrounds"></div>
</template>

<script>
export default {
  name: "clouds",
};
</script>

<style lang="scss">
@import "./index";
</style>
