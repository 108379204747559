import request from "@/api/request";

class UpdateService {
  static async getRecentList(page, check_date) {
    return await request.get("/updates", { page, check_date });
  }

  static async getUpdate(id) {
    return await request.get("/updates/" + id);
  }

  static async getLatest() {
    return await request.get("/updates/latest");
  }

  static async getNewAndUpcoming(check_date) {
    return await request.get("/updates/new_and_upcoming", { check_date });
  }

  static async unPinUpdate(id) {
    await request.delete("/updates/pinned/" + id);
  }
}

export default UpdateService;
