<template>
  <ul ref="rating" class="rating-stars" @mouseover="onStarsHover" @mouseleave="onStarsLeave">
    <li
      v-for="i in 5"
      :key="i"
      class="star"
      :class="[
        {
          'rating-full': i <= scaledRating,
          'rating-half': i - 0.5 === scaledRating,
          'rating-empty': i - 0.5 !== scaledRating && i > scaledRating,
          'user-rated': !ratedHover && i <= userRating,
          'user-not-rated': !ratedHover && i > userRating,
        },
      ]"
      @click="onSetRating(i)"
      @mouseover="highlight(i)"
      @mouseleave="removeHighlight"
    >
      <span class="item"></span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "rating-stars",
  props: {
    scaledRating: {
      type: Number,
      default: 0,
    },
    userRating: {
      type: Number,
      default: 0,
    },
    ratedHover: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ["onSetRating", "starsHover", "starsLeave"],
  methods: {
    onSetRating(value) {
      this.$emit("onSetRating", value);
    },
    onStarsHover() {
      this.$emit("starsHover");
    },
    onStarsLeave() {
      this.$emit("starsLeave");
    },
    highlight(index) {
      const ul = this.$refs.rating;
      const items = ul.getElementsByTagName("li");

      for (let i = 0; i < items.length; i++) {
        items[i].classList.remove("full");

        if (i <= index - 1) {
          items[i].classList.remove("empty");
          items[i].classList.add("full");
        } else {
          items[i].classList.add("empty");
        }
      }
    },
    removeHighlight() {
      const ul = this.$refs.rating;

      if (!ul) {
        return;
      }

      const items = ul.getElementsByTagName("li");

      for (let i = 0; i < items.length; i++) {
        items[i].classList.remove("full", "empty");
      }
    },
  },
};
</script>

<style lang="scss">
@import "./index";
</style>
