<template>
  <popup custom-class="texas-law-popup" :can-close="false">
    <!-- eslint-disable -->
    <div class="content">
      Dear user, <br /> <br />
      As you may know, your elected officials in Texas are requiring us to verify your age before allowing you access to our website. Not only
      does this impinge on the rights of adults to access protected speech, it fails strict scrutiny by employing the least effective and yet
      also most restrictive means of accomplishing Texas's stated purpose of allegedly protecting minors.<br /> <br />
      While safety and compliance are at the forefront of our mission, providing identification every time you want to visit an adult platform is
      not an effective solution for protecting users online, and in fact, will put minors and your privacy at risk.<br /> <br />
      Attempting to mandate age verification without any means to enforce at scale gives platforms the choice to comply or not, leaving thousands
      of platforms open and accessible.<br /> <br />
      As we've seen in other states, such bills have failed to protect minors, by driving users from those few websites which comply, to the
      thousands of websites, with far fewer safety measures in place, which do not comply. Very few sites are able to compare to the robust Trust
      and Safety measures we currently have in place. To protect minors and user privacy, any legislation must be enforced against all platforms
      offering adult content.
    </div>
    <!-- eslint-enable -->
  </popup>
</template>

<script>
import Popup from "@/components/popups/index.vue";

export default {
  name: "texas-law-popup",
  components: { Popup },
};
</script>

<style lang="scss">
@import "index";
</style>
