import request from "@/api/request";

class LandingService {
  static async getLandingPage({ id, lang }) {
    return await request.get(`/landings/${id ? id : ""}`, { lang });
  }

  static async getGalleryPage(id) {
    return await request.get(`/landings/${id}/gallery`);
  }

  static async getVideoPage({ id, videoId }) {
    return await request.get(`/landings/${id}/video/${videoId}`);
  }
}

export default LandingService;
