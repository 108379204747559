import blogService from "@/api/services/blogService";
import { amplitudeTrack } from "@/utils/amplitude";

export default () => {
  return {
    namespaced: true,
    state: {
      postCampaign: null,
      additionalContent: null,
    },

    getters: {
      getPostCampaign: (state) => state.postCampaign,
      getAdditionalContent: (state) => state.additionalContent,
    },

    mutations: {
      SET_POST_CAMPAIGN(state, campaign) {
        state.postCampaign = campaign;
      },
      SET_BLOG_CONTENT(state, content) {
        state.additionalContent = content;
      },
    },

    actions: {
      async setPostCampaign({ commit }, campaign) {
        commit("SET_POST_CAMPAIGN", campaign);
      },
      async getBlogContent({ commit }) {
        const response = await blogService.getAdditionalContent();
        commit("SET_BLOG_CONTENT", response);
      },
      track(_, name) {
        amplitudeTrack(name);
      },
    },
  };
};
