<template>
  <div
    class="rating-button btn btn-blue border-dark w-100"
    :class="[
      {
        'status-rated': !ratedHover && userRating,
        'status-not-rated': !userRating && !isStatic,
        'change-rating': ratedHover,
        static: isStatic,
      },
      size,
      theme,
    ]"
    @mouseover="onRatingHover"
    @mouseleave="onRatingLeave"
  >
    <div class="rating-text">{{ label }}</div>

    <rating-stars
      :scaled-rating="scaledRating"
      :user-rating="isStatic ? scaledRating : userRating"
      :rated-hover="ratedHover"
      @on-set-rating="setRating"
      @stars-hover="onStarsHover"
      @stars-leave="onStarsLeave"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import RatingStars from "../rating-stars";
import { addRating } from "@/utils/recommendor";

export default {
  name: "rating",
  components: { RatingStars },
  props: {
    value: {
      type: Number,
      required: true,
      default: 0,
    },
    type: {
      type: String,
      default: null,
      required: false,
    },
    entityId: {
      type: Number,
      default: null,
      required: false,
    },
    defaultLabel: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      required: false,
      default: "default",
      validator: function (value) {
        return value ? ["small", "default"].indexOf(value) !== -1 : true;
      },
    },
    theme: {
      type: String,
      required: false,
      default: "default",
      validator: (value) => {
        return ["default", "dark"].includes(value);
      },
    },
    recommendationSourceId: {
      type: String,
      required: false,
      default: null,
    },
    recommendationItemId: {
      type: String,
      required: false,
      default: null,
    },
    isStatic: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      rating: this.value,
      starsHover: false,
      ratedHover: false,
    };
  },
  computed: {
    ...mapGetters("rating", ["getRating", "getRatings"]),
    userRating() {
      if (this.isStatic) {
        return null;
      }

      return this.getRating(this.type, this.entityId) || null;
    },
    label() {
      if (this.starsHover) {
        this.defaultLabel || this.$t("Rate them");
      } else if (this.ratedHover) {
        return this.$t("Average");
      } else if (this.userRating !== null) {
        return this.$t("You rated");
      }

      return this.defaultLabel || this.$t("Rate them");
    },
    scaledRating() {
      const mod = this.rating % 1;

      if (mod >= 0.25 && mod <= 0.75) {
        return Math.floor(this.rating) + 0.5;
      }

      return Math.round(this.rating);
    },
  },
  methods: {
    async setRating(rating) {
      this.rating = await this.$store.dispatch("rating/updateRating", {
        type: this.type,
        id: this.entityId,
        value: rating,
      });

      this.ratedHover = false;
      this.starsHover = false;

      if (this.recommendationItemId) {
        addRating(this.recommendationItemId, rating, this.recommendationSourceId);
      }
    },
    onRatingHover() {
      if (this.userRating !== null) {
        this.ratedHover = true;
      }
    },
    onRatingLeave() {
      this.ratedHover = false;
    },
    onStarsHover() {
      this.starsHover = true;
    },
    onStarsLeave() {
      this.starsHover = false;
    },
  },
  mounted() {
    if (this.getRatings(this.type) === null && !this.isStatic) {
      this.$store.dispatch("rating/pullRatings", { type: this.type });
    }
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
