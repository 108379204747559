//todo: refactor as plugin
export default (player, sprites) => {
  const videojs = window.videojs;

  const dom = videojs.dom;
  const obj = videojs.obj;

  const controls = player.controlBar;
  const seekBar = controls?.progressControl?.seekBar;
  const seekBarEl = seekBar.el();

  let tooltipEl = document.querySelector(".mobile-sprite-tooltip");

  if (!tooltipEl) {
    tooltipEl = document.createElement("div");
    tooltipEl.classList.add("mobile-sprite-tooltip");

    seekBarEl.appendChild(tooltipEl);
  }

  const width = 160;
  const height = 90;
  const interval = 5;
  const columns = 1;
  const responsive = 600;

  const getUrl = (idx) => {
    return sprites[idx];
  };

  let tooltipTimeout = null;

  const hijackMouseTooltip = (evt) => {
    clearTimeout(tooltipTimeout);

    const duration = player.duration();
    const playerWidth = player.currentWidth();

    const rowDuration = interval * columns;
    const spriteDuration = rowDuration * (1 || Math.ceil(duration / rowDuration));

    let position = dom.getPointerPosition(seekBarEl, evt).x * duration;
    const idx = Math.floor(position / spriteDuration);

    position = (position - spriteDuration * idx) / interval;

    const scaleFactor = responsive && playerWidth < responsive ? playerWidth / responsive : 1;
    const scaledWidth = width * scaleFactor;
    const scaledHeight = height * scaleFactor;
    const cleft = Math.floor(position % columns) * -scaledWidth;
    const ctop = Math.floor(position / columns) * -scaledHeight;
    const controlsTop = dom.findPosition(controls.el()).top;
    const seekBarTop = dom.findPosition(seekBarEl).top;
    const topOffset = -scaledHeight - 5 - Math.max(0, seekBarTop - controlsTop);

    const tooltipWidth = tooltipEl.offsetWidth;
    const seekBarWidth = seekBarEl.offsetWidth;
    const leftOffset = Math.min(
      Math.max(dom.getPointerPosition(seekBarEl, evt).x * seekBarWidth - tooltipWidth / 2, 0),
      seekBarWidth - tooltipWidth,
    );

    tooltipEl.innerHTML = formatTime(seekBar.getProgress() * duration);

    const tooltipStyle = {
      display: "block",
      "z-index": "3",
      position: "absolute",
      backgroundImage: `url("${getUrl(idx)}")`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: `${cleft}px ${ctop}px`,
      backgroundSize: `${scaledWidth * columns}px auto`,
      top: `${topOffset}px`,
      left: `${leftOffset}px`,
      color: "#fff",
      textShadow: "1px 1px #000",
      border: "1px solid #000",
      width: `${scaledWidth + 2}px`,
      height: `${scaledHeight + 2}px`,
    };

    obj.each(tooltipStyle, (value, key) => {
      tooltipEl.style[key] = value;
    });

    tooltipTimeout = setTimeout(() => {
      tooltipEl.style.display = "none";
    }, 1000);
  };

  //todo: refactor time formatter
  const formatTime = (decimalTime) => {
    let hours = Math.floor(decimalTime / (60 * 60));
    decimalTime = decimalTime - hours * 60 * 60;

    let minutes = Math.floor(decimalTime / 60);
    decimalTime = decimalTime - minutes * 60;

    let seconds = Math.round(decimalTime);

    if (hours && hours < 10) {
      hours = "0" + hours;
    }

    if (minutes < 10) {
      minutes = "0" + minutes;
    }

    if (seconds < 10) {
      seconds = "0" + seconds;
    }

    return (hours ? hours + ":" : "") + minutes + ":" + seconds;
  };

  seekBarEl.addEventListener("touchmove", hijackMouseTooltip);
};
