import userService from "@/api/services/userService";
import store from "../../store/index";

export default async ({ to, redirect }) => {
  if (to.path !== "/unsubscribe_mails") {
    return true;
  }

  if (!store.getters["user/isLoggedIn"]) {
    redirect("/#log_in");
    return;
  }

  await userService.unsubscribeCameraMails();

  redirect("/user-profile/settings");
};
