<template>
  <teleport :to="container || 'body'">
    <template v-if="shown">
      <div v-if="!html" ref="tooltip" class="tooltip">
        <slot>{{ content }}</slot>
      </div>
      <div v-else ref="tooltip" class="tooltip" v-html="content" />
    </template>
  </teleport>
</template>

<script>
import { createPopper } from "@popperjs/core";
import { flip, preventOverflow } from "@popperjs/core/lib/modifiers";

export default {
  name: "app-tooltip",
  props: {
    content: {
      type: String,
      default: "",
    },
    container: {
      type: [String, null],
      default: null,
    },
    target: {
      type: [HTMLElement, String, Function],
      required: true,
    },
    placement: {
      type: String,
      default: "top",
      validator(value) {
        return [
          "auto",
          "auto-start",
          "auto-end",
          "top",
          "top-start",
          "top-end",
          "bottom",
          "bottom-start",
          "bottom-end",
          "right",
          "right-start",
          "right-end",
          "left",
          "left-start",
          "left-end",
        ].includes(value);
      },
    },
    trigger: {
      type: String,
      default: "hover",
      validator(value) {
        return ["hover", "click", "focus", "manual"].includes(value);
      },
    },
    html: {
      type: Boolean,
      default: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      instance: null,
      reference: null,
      shown: false,
    };
  },
  watch: {
    show(value) {
      if (value) {
        this.showTooltip();
      } else {
        this.hideTooltip();
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (typeof this.target === "string") {
        this.reference = document.querySelector(this.target);
      } else if (this.target instanceof Function) {
        this.reference = this.target();
      } else {
        this.reference = this.target;
      }

      if (!this.reference) {
        console.warn("Tooltip target not found");
        return;
      }

      const triggerEvents = this.triggers[this.trigger];

      if (!triggerEvents) {
        return;
      }

      for (const [event, callback] of Object.entries(triggerEvents)) {
        this.reference.addEventListener(event, callback);
      }
    });
  },
  beforeUnmount() {
    if (!this.triggers[this.trigger] || !this.reference) {
      return;
    }

    for (const [event, callback] of Object.entries(this.triggers[this.trigger])) {
      this.reference.removeEventListener(event, callback);
    }
  },
  methods: {
    showTooltip() {
      if (this.shown) {
        return;
      }

      this.shown = true;

      this.$nextTick(() => {
        this.instance = createPopper(this.reference, this.$refs.tooltip, {
          placement: this.placement,
          modifiers: [preventOverflow, flip],
        });
      });
    },
    hideTooltip() {
      if (!this.shown) {
        return;
      }

      this.instance.destroy();
      this.instance = null;
      this.shown = false;
    },
    toggleTooltip() {
      if (!this.shown) {
        this.showTooltip();
      } else {
        this.hideTooltip();
      }
    },
  },
  created() {
    this.triggers = {
      hover: {
        pointerenter: this.showTooltip,
        pointerleave: this.hideTooltip,
      },
      click: {
        click: this.toggleTooltip,
      },
      focus: {
        focus: this.showTooltip,
        blur: this.hideTooltip,
      },
    };
  },
};
</script>

<style lang="scss">
@import "./index.scss";
</style>
