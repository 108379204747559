<template>
  <div v-if="dataset.length" class="video-activity-graph">
    <svg :viewBox="viewbox">
      <g>
        <path :style="styles.path" :d="pathD"></path>
      </g>
    </svg>
  </div>
</template>

<script>
import videoService from "@/api/services/videoService";

export default {
  name: "video-activity-graph",
  props: {
    videoId: {
      type: [Number, String],
      required: true,
    },
    size: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dataset: [],
      options: {
        xMin: 0,
        xMax: 100,
        yMin: -5,
        yMax: 100,
      },
      smoothing: 0.15,
      flattening: 0,
    };
  },
  computed: {
    viewbox() {
      return `0 0 ${this.size.w} ${this.size.h}`;
    },
    styles() {
      return {
        path: {
          fill: "#fff",
          fillOpacity: 0.5,
        },
      };
    },
    pathD() {
      if (!this.pointsPositions.length) {
        return;
      }

      return this.pointsPositions.reduce(
        (acc, e, i, a) =>
          i === 0 ? `M ${a[a.length - 1][0]},${this.size.h}L ${e[0]},${this.size.h} L ${e[0]},${e[1]}` : `${acc} ${this.bezierCommand(e, i, a)}`,
        "",
      );
    },
    pointsPositions() {
      if (!this.dataset.length) {
        return [];
      }

      return this.dataset.map((e) => {
        const x = this.map(e[0], this.options.xMin, this.options.xMax, 0, this.size.w);
        const y = this.map(e[1], this.options.yMin, this.options.yMax, this.size.h, 0);
        return [x, y];
      });
    },
  },
  methods: {
    map(value, inMin, inMax, outMin, outMax) {
      return ((value - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },
    line(pointA, pointB) {
      const lengthX = pointB[0] - pointA[0];
      const lengthY = pointB[1] - pointA[1];
      return {
        length: Math.sqrt(Math.pow(lengthX, 2) + Math.pow(lengthY, 2)),
        angle: Math.atan2(lengthY, lengthX),
      };
    },
    controlPoint(current, previous, next, reverse) {
      const p = previous || current;
      const n = next || current;
      const o = this.line(p, n);
      const flat = this.map(Math.cos(o.angle) * this.flattening, 0, 1, 1, 0);
      const angle = o.angle * flat + (reverse ? Math.PI : 0);
      const length = o.length * this.smoothing;
      const x = current[0] + Math.cos(angle) * length;
      const y = current[1] + Math.sin(angle) * length;
      return [x, y];
    },
    bezierCommand(point, i, a) {
      const cps = this.controlPoint(a[i - 1], a[i - 2], point);
      const cpe = this.controlPoint(point, a[i - 1], a[i + 1], true);
      const close = i === a.length - 1 ? " z" : "";
      return `C ${cps[0]},${cps[1]} ${cpe[0]},${cpe[1]} ${point[0]},${point[1]}${close}`;
    },
  },
  async beforeMount() {
    try {
      this.dataset = Object.values(await videoService.getPlaybackData(this.videoId));

      this.options.xMax = this.dataset.reduce((max, current) => (current[0] > max[0] ? current : max))[0];
      this.options.yMax = this.dataset.reduce((max, current) => (current[1] > max[1] ? current : max))[1];
    } catch (e) {
      // ignore
    }
  },
};
</script>

<style lang="scss">
@import "index";
</style>
