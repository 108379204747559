import request from "@/api/request";

class PlaydateService {
  static async getProfile(id) {
    return request.get(`/playdates/private/camera/${id}`);
  }

  static async getAvailableTimeslots({ id, duration }) {
    return request.get(`/playdates/private/request/${id}`, { duration });
  }

  static async getClosestPublicPlaydate(id) {
    return request.get(`/playdates/camera/${id}/closest_public_playdate`);
  }

  static async getClosestPrivatePlaydate(id) {
    return request.get(`/playdates/camera/${id}/closest_private_playdate`);
  }

  static async getPendingRequest(id) {
    return request.get(`/playdates/camera/${id}/pending_request`);
  }

  static async request({ id, duration, preferred_date_1, preferred_date_2, preferred_date_3, wishes }) {
    return await request.post(`/playdates/private/request/${id}`, {
      duration,
      preferred_date_1,
      preferred_date_2,
      preferred_date_3,
      wishes,
    });
  }

  static async getCameras(page) {
    return request.get("/playdates/private/cameras", { page });
  }

  static async getPublicPlaydateList() {
    return request.get("/playdates/public/list");
  }

  static async getPrivatePlaydateList() {
    return request.get("/playdates/private/list");
  }

  static async getPrivatePlaydateExtend(id) {
    return request.get(`/playdates/private/extend/${id}`);
  }

  static async extend({ id, duration }) {
    return await request.post(`/playdates/private/extend/${id}`, {
      duration,
    });
  }

  static async getFeedback() {
    return request.get(`/playdates/feedback`);
  }

  static async sendFeedback({ id, rating, feedback_message, tip_amount, offline_message }) {
    return request.post(`/playdates/feedback/${id}`, {
      rating,
      feedback_message,
      tip_amount,
      offline_message,
    });
  }

  static async closeFeedback({ id }) {
    return request.get(`/playdates/feedback/close/${id}`);
  }

  static async getPlaydate({ type, id }) {
    return await request.get(`/playdates/${type}/${id}`);
  }

  static async checkPrivatePlaydateStatus(id) {
    return request.get(`/playdates/private/status/${id}`);
  }

  static async sendNotification({ type, id }) {
    return request.get(`/playdates/${type}/notification/${id}`);
  }

  static async checkRequestAvailability(id) {
    return request.get(`/playdates/private/request/check/${id}`);
  }

  static async getChatHistory(token) {
    return request.get(`/playdates/public/chat-history`, { token });
  }
}

export default PlaydateService;
