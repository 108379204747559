import { loadAccessToken, setAccessToken } from "@/utils/storage";
import authService from "../../api/services/authService";
import store from "@/store";

export default [
  async ({ to }) => {
    if (!loadAccessToken() || !to.matched.some((record) => record.meta.requiresAuth)) {
      return;
    }

    const restrictedHosts = JSON.parse(process.env.VUE_APP_RESTRICTED_MEMBERAREA_HOSTS);
    const host = window.location.host.split(".").slice(-2).join(".");

    if (restrictedHosts.includes(host)) {
      await new Promise(() => {
        window.location = process.env.VUE_APP_MEMBERAREA_HOST + to.fullPath + "?authToken=" + loadAccessToken();
      });
    }
  },
  async ({ to, redirect }) => {
    if (to.query.hash) {
      try {
        const accessToken = await authService.login_by_hash(to.query.hash);
        setAccessToken(accessToken);
        await store.dispatch("user/getProfile");
      } catch (e) {
        // ignore authentication error
      }

      delete to.query.hash;
      await redirect(to.path === "/" ? "/recent_updates" : to);
    }
  },
  async ({ to, redirect }) => {
    if (to.query.authToken) {
      try {
        setAccessToken(to.query.authToken);
        delete to.query.authToken;
        await store.dispatch("user/getProfile");
        await redirect(to.path === "/" ? "/recent_updates" : to);
      } catch (e) {
        // ignore authentication error
      }
    }
  },
  async () => {
    if (!store.getters["user/getProfile"] && loadAccessToken()) {
      await store.dispatch("user/getProfile");
    }
  },
  async ({ to }) => {
    const user = store.getters["user/getProfile"];
    const unauthorized = !user || !loadAccessToken();

    if (to.matched.some((record) => record.meta.requiresAuth) && unauthorized) {
      await store.dispatch("user/logout", {
        path: "/#log_in",
        query: { redirect: to.fullPath },
      });
      return false;
    }
  },
];
