import request from "@/api/request";

class FeatureService {
  static async setOrganicData(age, source, other_source) {
    return await request.post("/feature/set_organic_data", {
      age,
      source,
      other_source,
    });
  }

  static async addFeedback({ text, subject }) {
    return await request.post(`/feature/feedback`, { text, subject });
  }

  static async redditDiscountBuy() {
    return await request.post(`/feature/reddit_discount_buy`);
  }

  static async checkManualCancellation() {
    return await request.post(`/feature/cancellation_check`);
  }

  static async getFeatureFeedback(feature) {
    return await request.get(`/feature/feature_feedback/${feature}`);
  }

  static async setFeatureFeedback(feature, rating, message) {
    return await request.post(`/feature/feature_feedback/${feature}`, { rating, message });
  }

  static async getCompany() {
    return await request.post(`/feature/get_company`);
  }

  static async closeFeatureFeedback(feature) {
    return await request.delete(`/feature/feature_feedback/${feature}`);
  }

  static async setViewed(feature) {
    await request.post(`/feature/view/${feature}`);
  }

  static async getModelTipOfferPopup() {
    return await request.get(`/feature/model_tip_offer`);
  }

  static async trackAction(feature, action) {
    await request.post(`/feature/track_action/${feature}`, { action });
  }
}

export default FeatureService;
