import store from "../../store/index";
import { EXISTING_POPUPS } from "@/utils/variables";
import { getPopup } from "@/utils/storage";
import popupConfig from "@/configs/popup-config";

export default async ({ from, to }) => {
  let hash = from.hash.substring(1) || to.hash.substring(1) || "";
  hash = hash.split("?")[0];

  if (!hash || !EXISTING_POPUPS.includes(hash)) {
    return;
  }

  let toHash = to.hash.substring(1) || "";
  toHash = toHash.split("?")[0];

  if (
    toHash &&
    EXISTING_POPUPS.includes(toHash) &&
    ((!store.getters["user/isLoggedIn"] && popupConfig[toHash].requiresAuth) || !popupConfig[toHash].initOnHash)
  ) {
    history.pushState("", document.title, window.location.pathname);
    return;
  }

  if (!from.hash) {
    const popupData = getPopup(hash)?.data ?? null;
    store.dispatch("popup/show", { id: hash, data: popupData });
  }

  if (!to.hash) {
    store.dispatch("popup/hide", { id: hash });
  }
};
