import FeedsService from "@/api/services/feedsService";
import { FEED_SUBTYPE_VIDEO } from "@/utils/variables";

export default () => {
  return {
    namespaced: true,
    state: {
      feeds: {},
      positions: {},
      heroVideo: null,
    },
    getters: {
      getFeedContent: (state) => (type) => state.feeds[type] ?? [],
      getFeedPosition: (state) => (type) => state.positions[type] ?? 0,
      getHeroVideo: (state) => state.heroVideo,
    },
    mutations: {
      SET_FEED_CONTENT(state, { type, content }) {
        const existingContent = state.feeds[type] ?? [];

        const feed = {};
        feed[type] = [...existingContent, ...content];

        state.feeds = { ...state.feeds, ...feed };
      },
      SET_FEED_POSITION(state, { type, position }) {
        state.positions[type] = position;
      },
      RESET_FEEDS(state) {
        state.feeds = {};
        state.positions = {};
        state.heroVideos = [];
      },
      SET_HERO_VIDEO(state, video) {
        state.heroVideo = video;
      },
    },
    actions: {
      async getFeed({ commit }, { type, subtype, offset, rec_id = null }) {
        const content = await FeedsService.getFeed(type, offset, rec_id);
        commit("SET_FEED_CONTENT", { type, content });

        if (subtype === FEED_SUBTYPE_VIDEO) {
          content.forEach((video) => {
            commit("video/SET_VIDEO", video, { root: true });
          });
        }
      },
      setFeedPosition({ commit }, { type, position }) {
        commit("SET_FEED_POSITION", { type, position });
      },
      resetFeeds({ commit }) {
        commit("RESET_FEEDS");
      },
      async getHeroVideo({ commit }) {
        const response = await FeedsService.getHeroVideo();
        commit("SET_HERO_VIDEO", response);
      },
    },
  };
};
