export default () => {
  return {
    namespaced: true,
    state: {
      globalLoading: false,
      pageLoading: false,
      pagePositions: [],
      contentLoaded: false,
      isBackNavigation: false,
    },
    getters: {
      getGlobalLoading(state) {
        return state.globalLoading;
      },
      getPageLoading(state) {
        return state.pageLoading;
      },
      getPagePosition: (state) => {
        const positions = [...state.pagePositions];
        return positions.shift();
      },
      isBackNavigation: (state) => state.isBackNavigation,
      getContentLoaded: (state) => state.contentLoaded,
    },
    mutations: {
      SET_GLOBAL_LOADING(state, value) {
        state.globalLoading = value;
      },
      SET_PAGE_LOADING(state, value) {
        state.pageLoading = value;
      },
      SET_PAGE_POSITION(state, data) {
        const positions = state.pagePositions;
        positions.push(data);

        state.pagePositions = positions.slice(-2);
      },
      SET_IS_BACK_NAVIGATION(state, isBack) {
        state.isBackNavigation = isBack;
      },
      SET_CONTENT_LOADED(state, value) {
        state.contentLoaded = value;
      },
    },
    actions: {
      setGlobalLoading({ commit }, value) {
        commit("SET_GLOBAL_LOADING", value);
      },
      setPageLoading({ commit }, value) {
        commit("SET_PAGE_LOADING", value);
      },
      setPagePosition({ commit }, data) {
        commit("SET_PAGE_POSITION", data);
      },
      setIsBackNavigation({ commit }, isBack) {
        commit("SET_IS_BACK_NAVIGATION", isBack);
      },
      setContentLoaded({ commit }, value) {
        commit("SET_CONTENT_LOADED", value);
      },
    },
  };
};
