import store from "@/store";

export default async (to) => {
  const toHash = to.hash.substring(1) || "";
  const toParts = toHash.split("-");

  if (toParts[0] !== "play") {
    if (!store.getters["player/isPipModeEnabled"]) {
      await store.dispatch("player/setTheaterMode", { videoId: null });
    }

    return;
  }

  if (store.getters["video/getActiveVideo"] !== null) {
    return;
  }

  await store.dispatch("player/setTheaterMode", { videoId: toParts[1] });
};
