import request from "@/api/request";

class VisitService {
  static async trackVisit() {
    const urlParams = new URLSearchParams(location.search);
    const requestParams = { referer: document.referrer };

    for (const [key, value] of urlParams.entries()) {
      requestParams[key] = value;
    }

    return request.get("/track/visit", requestParams);
  }
}

export default VisitService;
